<template>
    <svg width="24" height="6" viewBox="0 0 24 6" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M3.99183 2.97125L3.13678 0.887687L2.28661 2.97125H3.99183ZM12.193 5.22649L12.1883 1.13314L10.3768 5.22649H9.27996L7.46376 1.12951V5.22649H4.92287L4.44285 4.06072H1.84174L1.35683 5.22649H0L2.23711 0H4.0932L6.21791 4.94841V0H8.25686L9.89177 3.54555L11.3936 0H13.4736V5.22649H12.193ZM15.4618 4.15703V3.11691H18.2045V2.05029H15.4618V1.09986H18.5939L19.9603 2.62333L18.5333 4.15703H15.4618ZM23.926 5.2465H22.3027L20.7644 3.5159L19.1658 5.2465H14.2174V0.0187504H19.2419L20.7789 1.73233L22.368 0.0187504H24L21.5722 2.63263L23.926 5.2465Z"
            fill="#002663"
        />
    </svg>
</template>
