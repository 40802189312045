<template>
    <svg width="160" height="48" viewBox="0 0 160 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M154.308 48H5.60474C2.51496 48 0 45.4464 0 42.3204V5.6712C0 2.544 2.51496 0 5.60474 0H154.308C157.396 0 160 2.544 160 5.6712V42.3204C160 45.4464 157.396 48 154.308 48Z"
            fill="#A6A6A6"
        />
        <path
            d="M158.853 42.3217C158.853 44.8609 156.821 46.9177 154.309 46.9177H5.60461C3.09321 46.9177 1.05469 44.8609 1.05469 42.3217V5.67012C1.05469 3.13212 3.09321 1.06812 5.60461 1.06812H154.307C156.821 1.06812 158.851 3.13212 158.851 5.67012L158.853 42.3217Z"
            fill="black"
        />
        <path
            d="M35.7086 23.7409C35.6742 19.8733 38.8363 17.9917 38.9809 17.9041C37.1901 15.2605 34.4144 14.8993 33.439 14.8705C31.1077 14.6221 28.8464 16.2829 27.6588 16.2829C26.4476 16.2829 24.6188 14.8945 22.6479 14.9353C20.1116 14.9749 17.7388 16.4617 16.4375 18.7705C13.7519 23.4781 15.7548 30.3961 18.3279 34.2013C19.615 36.0649 21.119 38.1457 23.0876 38.0725C25.0135 37.9921 25.7329 36.8293 28.057 36.8293C30.3599 36.8293 31.0354 38.0725 33.0431 38.0257C35.1101 37.9921 36.4114 36.1537 37.6535 34.2733C39.1409 32.1373 39.7382 30.0337 39.7619 29.9257C39.7133 29.9089 35.7477 28.3765 35.7086 23.7409Z"
            fill="white"
        />
        <path
            d="M31.916 12.3675C32.9519 11.0559 33.6606 9.27149 33.4639 7.46069C31.9646 7.52789 30.0896 8.51069 29.0099 9.79349C28.0547 10.9239 27.2013 12.7767 27.4218 14.5191C29.1059 14.6463 30.8351 13.6587 31.916 12.3675Z"
            fill="white"
        />
        <path
            d="M63.5792 37.8048H60.8877L59.4133 33.114H54.2886L52.8841 37.8048H50.2637L55.341 21.8352H58.477L63.5792 37.8048ZM58.9689 31.146L57.6355 26.976C57.4945 26.55 57.2302 25.5468 56.8403 23.9676H56.7929C56.6376 24.6468 56.3875 25.65 56.0438 26.976L54.7342 31.146H58.9689V31.146Z"
            fill="white"
        />
        <path
            d="M76.6359 31.9056C76.6359 33.864 76.1132 35.412 75.0679 36.5484C74.1316 37.56 72.9689 38.0652 71.5811 38.0652C70.083 38.0652 69.0069 37.5204 68.3514 36.4308H68.304V42.4968H65.7772V30.0804C65.7772 28.8492 65.7452 27.5856 65.6836 26.2896H67.9058L68.0469 28.1148H68.0943C68.9369 26.7396 70.2157 26.0532 71.9319 26.0532C73.2735 26.0532 74.3935 26.5896 75.2895 27.6636C76.1879 28.7388 76.6359 30.1524 76.6359 31.9056ZM74.0617 31.9992C74.0617 30.8784 73.8128 29.9544 73.3126 29.2272C72.7663 28.4688 72.0326 28.0896 71.1129 28.0896C70.4895 28.0896 69.923 28.3008 69.4169 28.7172C68.9097 29.1372 68.5778 29.6856 68.4226 30.3648C68.3443 30.6816 68.3052 30.9408 68.3052 31.1448V33.0648C68.3052 33.9024 68.5589 34.6092 69.0661 35.1864C69.5734 35.7636 70.2323 36.0516 71.043 36.0516C71.9947 36.0516 72.7354 35.6796 73.2652 34.938C73.7962 34.1952 74.0617 33.216 74.0617 31.9992Z"
            fill="white"
        />
        <path
            d="M89.7187 31.9056C89.7187 33.864 89.196 35.412 88.1495 36.5484C87.2144 37.56 86.0517 38.0652 84.6639 38.0652C83.1658 38.0652 82.0897 37.5204 81.4354 36.4308H81.388V42.4968H78.8612V30.0804C78.8612 28.8492 78.8292 27.5856 78.7676 26.2896H80.9898L81.1308 28.1148H81.1782C82.0197 26.7396 83.2985 26.0532 85.0159 26.0532C86.3563 26.0532 87.4763 26.5896 88.3747 27.6636C89.2695 28.7388 89.7187 30.1524 89.7187 31.9056ZM87.1445 31.9992C87.1445 30.8784 86.8944 29.9544 86.3942 29.2272C85.8479 28.4688 85.1166 28.0896 84.1957 28.0896C83.5711 28.0896 83.0058 28.3008 82.4985 28.7172C81.9913 29.1372 81.6606 29.6856 81.5054 30.3648C81.4283 30.6816 81.388 30.9408 81.388 31.1448V33.0648C81.388 33.9024 81.6416 34.6092 82.1465 35.1864C82.6538 35.7624 83.3128 36.0516 84.1258 36.0516C85.0775 36.0516 85.8182 35.6796 86.348 34.938C86.879 34.1952 87.1445 33.216 87.1445 31.9992Z"
            fill="white"
        />
        <path
            d="M104.342 33.3265C104.342 34.6849 103.876 35.7901 102.941 36.6433C101.913 37.5757 100.483 38.0413 98.6444 38.0413C96.9472 38.0413 95.5866 37.7101 94.5566 37.0465L95.1421 34.9141C96.2515 35.5933 97.4686 35.9341 98.7949 35.9341C99.7466 35.9341 100.487 35.7157 101.019 35.2813C101.549 34.8469 101.814 34.2637 101.814 33.5365C101.814 32.8885 101.595 32.3425 101.158 31.8997C100.723 31.4569 99.9966 31.0453 98.9821 30.6649C96.2206 29.6221 94.8411 28.0945 94.8411 26.0857C94.8411 24.7729 95.3246 23.6965 96.2929 22.8589C97.2577 22.0201 98.5448 21.6013 100.154 21.6013C101.59 21.6013 102.782 21.8545 103.734 22.3597L103.102 24.4453C102.213 23.9557 101.208 23.7109 100.083 23.7109C99.1943 23.7109 98.4998 23.9329 98.002 24.3745C97.5812 24.7693 97.3703 25.2505 97.3703 25.8205C97.3703 26.4517 97.6109 26.9737 98.0944 27.3841C98.5152 27.7633 99.2796 28.1737 100.389 28.6165C101.746 29.1697 102.743 29.8165 103.384 30.5581C104.023 31.2973 104.342 32.2225 104.342 33.3265Z"
            fill="white"
        />
        <path
            d="M112.696 28.2094H109.911V33.8002C109.911 35.2222 110.401 35.9326 111.385 35.9326C111.836 35.9326 112.211 35.893 112.507 35.8138L112.577 37.7566C112.079 37.945 111.424 38.0398 110.612 38.0398C109.614 38.0398 108.834 37.7314 108.271 37.1158C107.711 36.499 107.429 35.4646 107.429 34.0114V28.207H105.77V26.287H107.429V24.1786L109.911 23.4202V26.287H112.696V28.2094Z"
            fill="white"
        />
        <path
            d="M125.263 31.9524C125.263 33.7224 124.762 35.1756 123.765 36.312C122.718 37.482 121.329 38.0652 119.597 38.0652C117.929 38.0652 116.6 37.5048 115.609 36.384C114.618 35.2632 114.123 33.8484 114.123 32.1432C114.123 30.3588 114.633 28.8972 115.655 27.7608C116.676 26.6232 118.053 26.0544 119.785 26.0544C121.453 26.0544 122.796 26.6148 123.81 27.7368C124.779 28.8252 125.263 30.2304 125.263 31.9524ZM122.641 32.0352C122.641 30.9732 122.417 30.0624 121.963 29.3028C121.433 28.3836 120.676 27.9252 119.695 27.9252C118.679 27.9252 117.907 28.3848 117.378 29.3028C116.924 30.0636 116.7 30.9888 116.7 32.0832C116.7 33.1452 116.924 34.056 117.378 34.8144C117.924 35.7336 118.687 36.192 119.672 36.192C120.637 36.192 121.394 35.724 121.941 34.7904C122.406 34.0164 122.641 33.096 122.641 32.0352Z"
            fill="white"
        />
        <path
            d="M133.477 28.5396C133.227 28.4928 132.961 28.4688 132.681 28.4688C131.792 28.4688 131.105 28.8084 130.621 29.4888C130.2 30.0888 129.989 30.8472 129.989 31.7628V37.8048H127.464L127.487 29.916C127.487 28.5888 127.455 27.3804 127.393 26.2908H129.593L129.686 28.494H129.756C130.023 27.7368 130.443 27.1272 131.019 26.67C131.582 26.2584 132.19 26.0532 132.846 26.0532C133.079 26.0532 133.29 26.07 133.477 26.1V28.5396Z"
            fill="white"
        />
        <path
            d="M144.777 31.5023C144.777 31.9607 144.748 32.3471 144.685 32.6627H137.104C137.134 33.8003 137.5 34.6703 138.204 35.2703C138.843 35.8067 139.669 36.0755 140.684 36.0755C141.806 36.0755 142.83 35.8943 143.751 35.5307L144.147 37.3067C143.071 37.7819 141.8 38.0183 140.334 38.0183C138.57 38.0183 137.186 37.4927 136.179 36.4427C135.174 35.3927 134.67 33.9827 134.67 32.2139C134.67 30.4775 135.138 29.0315 136.076 27.8783C137.057 26.6471 138.383 26.0315 140.052 26.0315C141.691 26.0315 142.932 26.6471 143.775 27.8783C144.442 28.8563 144.777 30.0659 144.777 31.5023ZM142.368 30.8387C142.384 30.0803 142.22 29.4251 141.877 28.8719C141.44 28.1603 140.768 27.8051 139.863 27.8051C139.037 27.8051 138.365 28.1519 137.852 28.8479C137.431 29.4011 137.181 30.0647 137.104 30.8375H142.368V30.8387Z"
            fill="white"
        />
        <path
            d="M58.1348 12.0109C58.1348 13.4233 57.7164 14.4865 56.8809 15.2005C56.107 15.8593 55.0071 16.1893 53.5825 16.1893C52.8761 16.1893 52.2717 16.1581 51.7656 16.0957V8.37853C52.4258 8.27053 53.1369 8.21533 53.9049 8.21533C55.2619 8.21533 56.2847 8.51413 56.9745 9.11173C57.7473 9.78733 58.1348 10.7533 58.1348 12.0109ZM56.8252 12.0457C56.8252 11.1301 56.5858 10.4281 56.107 9.93853C55.6281 9.45013 54.9289 9.20533 54.008 9.20533C53.6169 9.20533 53.2838 9.23173 53.0077 9.28693V15.1537C53.1606 15.1777 53.4403 15.1885 53.8468 15.1885C54.7973 15.1885 55.531 14.9209 56.0477 14.3857C56.5644 13.8505 56.8252 13.0705 56.8252 12.0457Z"
            fill="white"
        />
        <path
            d="M65.0775 13.2445C65.0775 14.1145 64.8321 14.8273 64.3415 15.3865C63.8271 15.9613 63.1456 16.2481 62.2947 16.2481C61.4745 16.2481 60.8215 15.9733 60.3344 15.4213C59.8484 14.8705 59.6055 14.1757 59.6055 13.3381C59.6055 12.4621 59.8555 11.7433 60.3581 11.1853C60.8606 10.6273 61.5361 10.3477 62.3871 10.3477C63.2072 10.3477 63.8662 10.6225 64.3652 11.1733C64.8392 11.7085 65.0775 12.3997 65.0775 13.2445ZM63.7892 13.2853C63.7892 12.7633 63.6778 12.3157 63.4561 11.9425C63.1954 11.4913 62.8244 11.2657 62.3421 11.2657C61.8431 11.2657 61.4638 11.4913 61.2031 11.9425C60.9803 12.3157 60.8701 12.7705 60.8701 13.3081C60.8701 13.8301 60.9815 14.2777 61.2031 14.6509C61.4721 15.1021 61.8467 15.3277 62.3302 15.3277C62.8043 15.3277 63.1764 15.0985 63.4443 14.6389C63.6742 14.2585 63.7892 13.8073 63.7892 13.2853Z"
            fill="white"
        />
        <path
            d="M74.3897 10.4626L72.6416 16.1194H71.5038L70.7797 13.663C70.596 13.0498 70.4466 12.4402 70.3305 11.8354H70.308C70.2001 12.457 70.0508 13.0654 69.8588 13.663L69.0896 16.1194H67.9388L66.2949 10.4626H67.5714L68.2031 13.1518C68.356 13.7878 68.4816 14.3938 68.5823 14.9674H68.6048C68.6973 14.4946 68.8502 13.8922 69.0659 13.1638L69.8588 10.4638H70.8709L71.6306 13.1062C71.8143 13.7506 71.9637 14.371 72.0786 14.9686H72.113C72.1971 14.3866 72.324 13.7662 72.4923 13.1062L73.1702 10.4638H74.3897V10.4626Z"
            fill="white"
        />
        <path
            d="M80.8288 16.1196H79.5868V12.8796C79.5868 11.8812 79.2122 11.382 78.4608 11.382C78.0922 11.382 77.7948 11.5188 77.5636 11.7936C77.3349 12.0684 77.2188 12.3924 77.2188 12.7632V16.1184H75.9767V12.0792C75.9767 11.5824 75.9613 11.0436 75.9316 10.4604H77.0232L77.0813 11.3448H77.1156C77.2602 11.07 77.4759 10.8432 77.7592 10.662C78.0958 10.4508 78.4727 10.344 78.8851 10.344C79.4066 10.344 79.8404 10.5144 80.1853 10.8564C80.6143 11.2752 80.8288 11.9004 80.8288 12.7308V16.1196V16.1196Z"
            fill="white"
        />
        <path d="M84.2526 16.1196H83.0117V7.86719H84.2526V16.1196Z" fill="white" />
        <path
            d="M91.5658 13.2445C91.5658 14.1145 91.3204 14.8273 90.8298 15.3865C90.3154 15.9613 89.6327 16.2481 88.7829 16.2481C87.9616 16.2481 87.3086 15.9733 86.8226 15.4213C86.3367 14.8705 86.0938 14.1757 86.0938 13.3381C86.0938 12.4621 86.3438 11.7433 86.8463 11.1853C87.3489 10.6273 88.0244 10.3477 88.8742 10.3477C89.6955 10.3477 90.3533 10.6225 90.8535 11.1733C91.3275 11.7085 91.5658 12.3997 91.5658 13.2445ZM90.2763 13.2853C90.2763 12.7633 90.1649 12.3157 89.9432 11.9425C89.6837 11.4913 89.3115 11.2657 88.8303 11.2657C88.3302 11.2657 87.9509 11.4913 87.6914 11.9425C87.4686 12.3157 87.3583 12.7705 87.3583 13.3081C87.3583 13.8301 87.4697 14.2777 87.6914 14.6509C87.9604 15.1021 88.3349 15.3277 88.8185 15.3277C89.2926 15.3277 89.6635 15.0985 89.9314 14.6389C90.1625 14.2585 90.2763 13.8073 90.2763 13.2853Z"
            fill="white"
        />
        <path
            d="M97.5769 16.1197H96.4616L96.3692 15.4681H96.3348C95.9532 15.9877 95.4092 16.2481 94.7028 16.2481C94.1754 16.2481 93.7488 16.0765 93.4276 15.7357C93.136 15.4261 92.9902 15.0409 92.9902 14.5837C92.9902 13.8925 93.2747 13.3657 93.8471 13.0009C94.4184 12.6361 95.2219 12.4573 96.2566 12.4657V12.3601C96.2566 11.6149 95.8702 11.2429 95.0963 11.2429C94.5452 11.2429 94.0593 11.3833 93.6397 11.6617L93.3873 10.8361C93.9064 10.5109 94.5476 10.3477 95.3037 10.3477C96.7639 10.3477 97.4963 11.1277 97.4963 12.6877V14.7709C97.4963 15.3361 97.5236 15.7861 97.5769 16.1197ZM96.2874 14.1757V13.3033C94.9173 13.2793 94.2323 13.6597 94.2323 14.4433C94.2323 14.7385 94.3105 14.9593 94.4705 15.1069C94.6305 15.2545 94.8344 15.3277 95.0773 15.3277C95.3499 15.3277 95.6048 15.2401 95.837 15.0661C96.0705 14.8909 96.2139 14.6689 96.2673 14.3965C96.2803 14.3353 96.2874 14.2609 96.2874 14.1757Z"
            fill="white"
        />
        <path
            d="M104.634 16.1196H103.532L103.474 15.2112H103.439C103.087 15.9024 102.488 16.248 101.645 16.248C100.972 16.248 100.411 15.9804 99.9669 15.4452C99.5224 14.91 99.3008 14.2152 99.3008 13.362C99.3008 12.4464 99.5414 11.7048 100.025 11.1384C100.493 10.6104 101.067 10.3464 101.749 10.3464C102.5 10.3464 103.025 10.602 103.323 11.1144H103.347V7.86719H104.59V14.5956C104.59 15.1464 104.604 15.654 104.634 16.1196ZM103.347 13.734V12.7908C103.347 12.6276 103.335 12.4956 103.313 12.3948C103.243 12.0924 103.092 11.838 102.863 11.6328C102.632 11.4276 102.354 11.3244 102.033 11.3244C101.569 11.3244 101.207 11.5104 100.94 11.8836C100.676 12.2568 100.542 12.7332 100.542 13.3152C100.542 13.8744 100.668 14.328 100.923 14.6772C101.192 15.0492 101.555 15.2352 102.009 15.2352C102.417 15.2352 102.743 15.0804 102.99 14.7696C103.23 14.4828 103.347 14.1372 103.347 13.734Z"
            fill="white"
        />
        <path
            d="M115.257 13.2445C115.257 14.1145 115.012 14.8273 114.521 15.3865C114.007 15.9613 113.326 16.2481 112.474 16.2481C111.655 16.2481 111.002 15.9733 110.514 15.4213C110.028 14.8705 109.785 14.1757 109.785 13.3381C109.785 12.4621 110.035 11.7433 110.538 11.1853C111.04 10.6273 111.716 10.3477 112.568 10.3477C113.387 10.3477 114.047 10.6225 114.545 11.1733C115.019 11.7085 115.257 12.3997 115.257 13.2445ZM113.97 13.2853C113.97 12.7633 113.859 12.3157 113.637 11.9425C113.375 11.4913 113.005 11.2657 112.522 11.2657C112.024 11.2657 111.645 11.4913 111.383 11.9425C111.16 12.3157 111.05 12.7705 111.05 13.3081C111.05 13.8301 111.161 14.2777 111.383 14.6509C111.652 15.1021 112.026 15.3277 112.51 15.3277C112.984 15.3277 113.357 15.0985 113.625 14.6389C113.854 14.2585 113.97 13.8073 113.97 13.2853Z"
            fill="white"
        />
        <path
            d="M121.937 16.1196H120.696V12.8796C120.696 11.8812 120.322 11.382 119.569 11.382C119.2 11.382 118.903 11.5188 118.673 11.7936C118.443 12.0684 118.328 12.3924 118.328 12.7632V16.1184H117.085V12.0792C117.085 11.5824 117.071 11.0436 117.041 10.4604H118.131L118.189 11.3448H118.224C118.37 11.07 118.585 10.8432 118.867 10.662C119.205 10.4508 119.581 10.344 119.995 10.344C120.515 10.344 120.949 10.5144 121.293 10.8564C121.724 11.2752 121.937 11.9004 121.937 12.7308V16.1196V16.1196Z"
            fill="white"
        />
        <path
            d="M130.297 11.4048H128.929V14.1528C128.929 14.8512 129.172 15.2004 129.654 15.2004C129.876 15.2004 130.061 15.1812 130.207 15.1416L130.239 16.0956C129.994 16.1892 129.671 16.236 129.274 16.236C128.784 16.236 128.402 16.0848 128.126 15.7824C127.849 15.48 127.711 14.9712 127.711 14.2572V11.4048H126.895V10.4628H127.711V9.42595L128.928 9.05396V10.4616H130.296V11.4048H130.297Z"
            fill="white"
        />
        <path
            d="M136.872 16.1196H135.628V12.9036C135.628 11.8896 135.254 11.382 134.504 11.382C133.928 11.382 133.534 11.676 133.318 12.264C133.282 12.3876 133.26 12.5388 133.26 12.7164V16.1184H132.02V7.86719H133.26V11.2764H133.284C133.675 10.656 134.236 10.3464 134.962 10.3464C135.477 10.3464 135.902 10.5168 136.24 10.8588C136.661 11.2848 136.872 11.9184 136.872 12.756V16.1196V16.1196Z"
            fill="white"
        />
        <path
            d="M143.655 13.0235C143.655 13.2491 143.638 13.4387 143.608 13.5935H139.883C139.9 14.1527 140.078 14.5787 140.423 14.8739C140.738 15.1379 141.144 15.2699 141.642 15.2699C142.193 15.2699 142.696 15.1811 143.149 15.0023L143.343 15.8759C142.813 16.1087 142.19 16.2251 141.468 16.2251C140.603 16.2251 139.921 15.9671 139.428 15.4511C138.933 14.9351 138.688 14.2427 138.688 13.3739C138.688 12.5207 138.916 11.8103 139.377 11.2439C139.858 10.6391 140.509 10.3367 141.33 10.3367C142.134 10.3367 142.744 10.6391 143.157 11.2439C143.49 11.7239 143.655 12.3179 143.655 13.0235ZM142.469 12.6983C142.479 12.3251 142.397 12.0035 142.229 11.7323C142.013 11.3831 141.685 11.2079 141.24 11.2079C140.835 11.2079 140.504 11.3783 140.252 11.7203C140.046 11.9927 139.924 12.3179 139.883 12.6983H142.469Z"
            fill="white"
        />
    </svg>
</template>
