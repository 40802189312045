import { U_GET_MODULES_BOOLEANS } from 'platform-module'

/**
 * Object containing boolean flags for various modules.
 * @typedef {object} ModuleBooleans
 * @property {boolean} HAS_BILLING - Flag indicating whether billing module is enabled. Optional.
 * @property {boolean} HAS_FM - Flag indicating whether family sharing module is enabled. Optional.
 * @property {boolean} HAS_REFERRAL - Flag indicating whether referral module is enabled. Optional.
 * @property {boolean} HAS_USERS - Flag indicating whether users module is enabled. Required.
 * @property {boolean} HAS_LOGGING - Flag indicating whether logging module is enabled. Optional.
 */

/**
 * Constant containing boolean flags for various modules.
 * @type {Readonly<ModuleBooleans>}
 * @readonly
 */
export const MODULES_BOOLEANS = Object.freeze(U_GET_MODULES_BOOLEANS())
