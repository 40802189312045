<template>
  <svg width="448" height="512" viewBox="0 0 448 512" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_87:4)">
      <path
        d="M32 464C32 476.73 37.0571 488.939 46.0589 497.941C55.0606 506.943 67.2696 512 80 512H368C380.73 512 392.939 506.943 401.941 497.941C410.943 488.939 416 476.73 416 464V128H32V464ZM304 208C304 203.756 305.686 199.687 308.686 196.686C311.687 193.686 315.757 192 320 192C324.243 192 328.313 193.686 331.314 196.686C334.314 199.687 336 203.756 336 208V432C336 436.243 334.314 440.313 331.314 443.314C328.313 446.314 324.243 448 320 448C315.757 448 311.687 446.314 308.686 443.314C305.686 440.313 304 436.243 304 432V208ZM208 208C208 203.756 209.686 199.687 212.686 196.686C215.687 193.686 219.757 192 224 192C228.243 192 232.313 193.686 235.314 196.686C238.314 199.687 240 203.756 240 208V432C240 436.243 238.314 440.313 235.314 443.314C232.313 446.314 228.243 448 224 448C219.757 448 215.687 446.314 212.686 443.314C209.686 440.313 208 436.243 208 432V208ZM112 208C112 203.756 113.686 199.687 116.686 196.686C119.687 193.686 123.757 192 128 192C132.243 192 136.313 193.686 139.314 196.686C142.314 199.687 144 203.756 144 208V432C144 436.243 142.314 440.313 139.314 443.314C136.313 446.314 132.243 448 128 448C123.757 448 119.687 446.314 116.686 443.314C113.686 440.313 112 436.243 112 432V208ZM432 31.9999H312L302.6 13.2999C300.609 9.30207 297.541 5.93916 293.743 3.58952C289.945 1.23987 285.566 -0.00327211 281.1 -6.88134e-05H166.8C162.344 -0.0171994 157.973 1.22131 154.188 3.57357C150.403 5.92582 147.358 9.29668 145.4 13.2999L136 31.9999H16C11.7565 31.9999 7.68687 33.6856 4.68629 36.6862C1.68571 39.6868 0 43.7565 0 47.9999L0 79.9999C0 84.2434 1.68571 88.3131 4.68629 91.3136C7.68687 94.3142 11.7565 95.9999 16 95.9999H432C436.243 95.9999 440.313 94.3142 443.314 91.3136C446.314 88.3131 448 84.2434 448 79.9999V47.9999C448 43.7565 446.314 39.6868 443.314 36.6862C440.313 33.6856 436.243 31.9999 432 31.9999V31.9999Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_87:4">
        <rect width="448" height="512" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>