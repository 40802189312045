<template>
    <svg width="24" height="16" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_192_55)">
            <path
                d="M21.9972 15.7499L21.9994 15.7499C22.9545 15.7581 23.7381 14.9773 23.75 14.0042L23.75 2.0063C23.7462 1.53569 23.5589 1.08617 23.2297 0.756802C22.9014 0.428269 22.4589 0.246149 21.9972 0.250071L2.00064 0.250062C1.54109 0.246149 1.09858 0.428269 0.770279 0.756802C0.441145 1.08617 0.253838 1.53569 0.250008 2.00426L0.25 13.9937C0.253838 14.4643 0.441145 14.9138 0.770279 15.2432C1.09858 15.5717 1.54109 15.7538 2.00277 15.7499H21.9972ZM21.9962 16.2499C21.9958 16.2499 21.9955 16.2499 21.9951 16.2499L21.9972 16.2499H21.9962Z"
                fill="white"
                stroke="black"
                stroke-opacity="0.2"
                stroke-width="0.5"
            />
            <path
                d="M10.0021 2.05164V2.03564H14.0021V2.05164C17.1375 2.28108 19.5642 4.89183 19.5642 8.03564C19.5642 11.1795 17.1375 13.7902 14.0021 14.0196V14.0356H10.0021V14.0196C6.86662 13.7902 4.43994 11.1795 4.43994 8.03564C4.43994 4.89183 6.86662 2.28108 10.0021 2.05164Z"
                fill="#0165AC"
            />
            <path
                d="M11.6021 11.4279C13.0374 10.9176 13.9962 9.55924 13.9962 8.03594C13.9962 6.51264 13.0374 5.15422 11.6021 4.64394V11.4279ZM9.20215 4.64394C7.76686 5.15422 6.80811 6.51264 6.80811 8.03594C6.80811 9.55924 7.76686 10.9176 9.20215 11.4279V4.64394ZM10.4021 13.2359C7.53027 13.2359 5.20215 10.9078 5.20215 8.03594C5.20215 5.16406 7.53027 2.83594 10.4021 2.83594C13.274 2.83594 15.6021 5.16406 15.6021 8.03594C15.6021 10.9078 13.274 13.2359 10.4021 13.2359Z"
                fill="white"
            />
        </g>
        <defs>
            <clipPath id="clip0_192_55">
                <rect width="24" height="16" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>
