<template>
    <div class="new-container" :class="ParentClassName">
        <NewLoader v-if="loading" size="2xl" />
        <slot v-else />
    </div>
</template>

<script setup lang="ts">
import { NewLoader } from 'vue-components2'
import { computed } from 'vue'
import { useDebugInfoStore } from '@/stores'

const props = defineProps({
    loading: {
        type: Boolean,
        default: false
    }
})

const { debugInfoBooleans } = useDebugInfoStore()

const ParentClassName = computed(() => {
    return {
        'new-container--test-border': debugInfoBooleans.containerBorder.val,
        'is-loading': props.loading
    }
})
</script>

<style scoped lang="scss">
@import '~/styles/modules/container.scss';

.new-container {
    @extend .new-container;
}
</style>
