// COMMON COMPONENTS START
import {
    AppAccordion,
    AppButton,
    AppCheckbox,
    CreditCard as AppCreditCard,
    AppDropdown,
    AppIcon,
    AppInput,
    AppLoader,
    AppModal,
    AppStatus,
    AppTooltip,
    CustomSwitch
} from 'vue-components'
import { library } from '@fortawesome/fontawesome-svg-core' /* import the fontawesome core */
import {
    faCaretDown,
    faChevronLeft,
    faCircleCheck,
    faCircleXmark,
    faCloudArrowDown,
    faDownload,
    faPencil,
    faSortDown,
    faTrash,
    faTriangleExclamation,
    faUser,
    faXmark,
    faCircleInfo as fasCircleInfoFree
} from '@fortawesome/free-solid-svg-icons'
import {
    faCirclePause,
    faCirclePlay,
    faCopy,
    faEnvelopeOpen,
    faEye,
    faEyeSlash
} from '@fortawesome/free-regular-svg-icons'
import { faApple, faChrome, faLinux, faWindows } from '@fortawesome/free-brands-svg-icons'
import {
    faArrowRight,
    faArrowRightFromBracket,
    faBan,
    faLaptopMobile,
    faLock,
    faUserGroup
} from '@fortawesome/pro-regular-svg-icons'
import { faCircleCheck as faCircleCheckLight, faCircleInfo, faPen } from '@fortawesome/pro-light-svg-icons'
import { faGear } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome' /* import font awesome icon component */
import AppSelect from '@/components/AppSelect.vue'
import ModalConfirmation from '@/components/Modals/ModalConfirmation.vue'
import LanguageSelect from '@/components/LanguageSelect.vue' // ??
import AppLogo from '@/components/AppLogo.vue'

import PageWrapper from '@/components/PageWrapper/PageWrapper.vue'
import NewContainer from '@/components/Container.vue'
import IconLogo from '@/components/Icons/IconLogo.vue'
import BrandsIconGooglePlayStore from '@/components/Icons/Brands/BrandsIconGooglePlayStore.vue'
import BrandsIconAppStore from '@/components/Icons/Brands/BrandsIconAppStore.vue'
import BrandsIconAndroid from '@/components/Icons/Brands/BrandsIconAndroid.vue'
import BrandsIconIos from '@/components/Icons/Brands/BrandsIconIos.vue'
import BrandsIconApple from '@/components/Icons/Brands/BrandsIconApple.vue'

import IconPaypalWord from '@/components/Icons/IconPaypalWord.vue'
import IconPaypal from '@/components/Icons/IconPaypal.vue'

import FlagEn from '@/components/Icons/FlagEn.vue'
import FlagEs from '@/components/Icons/FlagEs.vue'
import FlagFr from '@/components/Icons/FlagFr.vue'
import FlagNl from '@/components/Icons/FlagNl.vue'
import FlagRu from '@/components/Icons/FlagRu.vue'

import FAenvelope from '@/components/Icons/FAenvelope.vue'
import FAeye from '@/components/Icons/FAeye.vue'
import FAeyeSlash from '@/components/Icons/FAeyeSlash.vue'
import FAlock from '@/components/Icons/FAlock.vue'
import FAangleDown from '@/components/Icons/FAangleDown.vue'
import FAbell from '@/components/Icons/FAbell.vue'
import FApencilAlt from '@/components/Icons/FApencilAlt.vue'
import FAtrashAlt from '@/components/Icons/FAtrashAlt.vue'
import FAexclamationTriangle from '@/components/Icons/FAexclamationTriangle.vue'
import FAexclamationCircle from '@/components/Icons/FAexclamationCircle.vue'
import FAcheckSquare from '@/components/Icons/FAcheckSquare.vue'
import FAinfoCircle from '@/components/Icons/FAinfoCircle.vue'
import FAtimesCircle from '@/components/Icons/FAtimesCircle.vue'
import FAcheck from '@/components/Icons/FAcheck.vue'
import FAcreditCardFront from '@/components/Icons/FAcreditCardFront.vue'
import FAcreditCard from '@/components/Icons/FAcreditCard.vue'
import FAplusCircle from '@/components/Icons/FAplusCircle.vue'
import FAplus from '@/components/Icons/FAplus.vue'
import FAminusCircle from '@/components/Icons/FAminusCircle.vue'
import FAtime from '@/components/Icons/FAtime.vue'
import FAlightPlus from '@/components/Icons/FAlightPlus.vue'
import FAlightChevronRight from '@/components/Icons/FAlightChevronRight.vue'
import FAlightCheck from '@/components/Icons/FAlightCheck.vue'
import FAsolidFilter from '@/components/Icons/FAsolidFilter.vue'
import FAsolidDownload from '@/components/Icons/FAsolidDownload.vue'
import FAsolidCalendarAlt from '@/components/Icons/FAsolidCalendarAlt.vue'
import FAsolidSearch from '@/components/Icons/FAsolidSearch.vue'
import FAsolidXmark from '@/components/Icons/FAsolidXmark.vue'
import FAsolidCaretDown from '@/components/Icons/FAsolidCaretDown.vue'
import FAregularExternalLinkAlt from '@/components/Icons/FAregularExternalLinkAlt.vue'
import FAregularCheck from '@/components/Icons/FAregularCheck.vue'
import FAsolidCheck from '@/components/Icons/FAsolidCheck.vue'
import FAsolidTraingleExclamation from '@/components/Icons/FAsolidTraingleExclamation.vue'
import FAlightLockKeyhole from '@/components/Icons/FAlightLockKeyhole.vue'
import FAregularLockKeyhole from '@/components/Icons/FAregularLockKeyhole.vue'
import FAsolidCircleExlamation from '@/components/Icons/FAsolidCircleExlamation.vue'
import FAregularCircleExlamation from '@/components/Icons/FAregularCircleExlamation.vue'

import CreditCardAmericanExpress from '@/components/Icons/CreditCardAmericanExpress.vue'
import CreditCardAmex from '@/components/Icons/CreditCardAmex.vue'
import CreditCardDiners from '@/components/Icons/CreditCardDiners.vue'
import CreditCardDiscover from '@/components/Icons/CreditCardDiscover.vue'
import CreditCardMaster from '@/components/Icons/CreditCardMaster.vue'
import CreditCardVisa from '@/components/Icons/CreditCardVisa.vue'
// COMMON COMPONENTS END

// FONTAWESOME ICON COMPONENTS START

/* import specific icons */

export function INIT_COMMON_COMPONENTS(app) {
    app.component('AppInput', AppInput)
    app.component('AppButton', AppButton)
    app.component('AppModal', AppModal)
    app.component('ModalConfirmation', ModalConfirmation)
    app.component('AppLogo', AppLogo)
    app.component('LanguageSelect', LanguageSelect)
    app.component('AppLoader', AppLoader)
    app.component('AppCreditCard', AppCreditCard)
    app.component('CustomSwitch', CustomSwitch)
    app.component('AppSelect', AppSelect)
    app.component('AppDropdown', AppDropdown)
    app.component('AppTooltip', AppTooltip)
    app.component('AppAccordion', AppAccordion)
    app.component('AppIcon', AppIcon)
    app.component('AppCheckbox', AppCheckbox)
    app.component('AppStatus', AppStatus)
    app.component('NewContainer', NewContainer)
    app.component('PageWrapper', PageWrapper)

    app.config.globalProperties.$modalConfirmation2 = ModalConfirmation

    app.component('IconLogo', IconLogo)
    app.component('BrandsIconGooglePlayStore', BrandsIconGooglePlayStore)
    app.component('BrandsIconAppStore', BrandsIconAppStore)
    app.component('BrandsIconAndroid', BrandsIconAndroid)
    app.component('BrandsIconIos', BrandsIconIos)
    app.component('BrandsIconApple', BrandsIconApple)

    app.component('IconPaypalWord', IconPaypalWord)
    app.component('IconPaypal', IconPaypal)

    app.component('FlagEn', FlagEn)
    app.component('FlagEs', FlagEs)
    app.component('FlagFr', FlagFr)
    app.component('FlagNl', FlagNl)
    app.component('FlagRu', FlagRu)

    app.component('FAenvelope', FAenvelope)
    app.component('FAeye', FAeye)
    app.component('FAeyeSlash', FAeyeSlash)
    app.component('FAlock', FAlock)
    app.component('FAangleDown', FAangleDown)
    app.component('FAbell', FAbell)
    app.component('FApencilAlt', FApencilAlt)
    app.component('FAtrashAlt', FAtrashAlt)
    app.component('FAexclamationTriangle', FAexclamationTriangle)
    app.component('FAexclamationCircle', FAexclamationCircle)
    app.component('FAcheckSquare', FAcheckSquare)
    app.component('FAinfoCircle', FAinfoCircle)
    app.component('FAtimesCircle', FAtimesCircle)
    app.component('FAtime', FAtime)
    app.component('FAcheck', FAcheck)
    app.component('FAcreditCardFront', FAcreditCardFront)
    app.component('FAcreditCard', FAcreditCard)
    app.component('FAplusCircle', FAplusCircle)
    app.component('FAplus', FAplus)
    app.component('FAlightPlus', FAlightPlus)
    app.component('FAlightChevronRight', FAlightChevronRight)
    app.component('FAlightCheck', FAlightCheck)
    app.component('FAminusCircle', FAminusCircle)
    app.component('FAsolidFilter', FAsolidFilter)
    app.component('FAsolidDownload', FAsolidDownload)
    app.component('FAsolidCalendarAlt', FAsolidCalendarAlt)
    app.component('FAsolidSearch', FAsolidSearch)
    app.component('FAsolidXmark', FAsolidXmark)
    app.component('FAsolidCaretDown', FAsolidCaretDown)
    app.component('FAregularExternalLinkAlt', FAregularExternalLinkAlt)
    app.component('FAregularCheck', FAregularCheck)
    app.component('FAsolidCheck', FAsolidCheck)
    app.component('FAsolidTraingleExclamation', FAsolidTraingleExclamation)
    app.component('FAlightLockKeyhole', FAlightLockKeyhole)
    app.component('FAregularLockKeyhole', FAregularLockKeyhole)
    app.component('FAsolidCircleExlamation', FAsolidCircleExlamation)
    app.component('FAregularCircleExlamation', FAregularCircleExlamation)

    app.component('CreditCardAmericanExpress', CreditCardAmericanExpress)
    app.component('CreditCardAmex', CreditCardAmex)
    app.component('CreditCardDiners', CreditCardDiners)
    app.component('CreditCardDiscover', CreditCardDiscover)
    app.component('CreditCardMaster', CreditCardMaster)
    app.component('CreditCardVisa', CreditCardVisa)
}

export function INIT_FA_ICONS(app) {
    const icons = [
        faChevronLeft,
        faLaptopMobile,
        faArrowRightFromBracket,
        faCircleXmark,
        faDownload,
        faPen,
        faArrowRight,
        faSortDown,
        faCaretDown,
        faCirclePause,
        faCirclePlay,
        faBan,
        faGear,
        faPencil,
        faTrash,
        faWindows,
        faLinux,
        faChrome,
        faApple,
        faCopy,
        faEye,
        faEyeSlash,
        faCircleInfo,
        faCloudArrowDown,
        faEnvelopeOpen,
        faUserGroup,
        faXmark,
        faTriangleExclamation,
        faCircleCheck,
        faCircleCheckLight,
        fasCircleInfoFree,
        faUser,
        faLock
    ]

    icons.forEach(el => library.add(el)) /* add icons to the library */

    app.component('FontAwesomeIcon', FontAwesomeIcon) /* add font awesome icon component */
}
// FONTAWESOME ICON COMPONENTS END
