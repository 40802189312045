import { createRouter, createWebHistory } from 'vue-router'
import { DEBUG_ERROR, DEBUG_LOG, U_GET_COOKIE, U_VALIDATE_ROUTING } from 'platform-module'
import { U_GET_AUTH_ROLE } from 'auth-module'
import { useAppStore, useAuthStore } from '@/stores'
import { useAccountBillingStore } from '@/stores/account/AccountBillingStore.js'
import { doRequestsBeforeLoad } from '@/router/router-functions.js'

window.reload = true

function createRouterFunction({ routes, routeNames }) {
    const router = createRouter({ history: createWebHistory(), routes })
    const locales = window.configs.locales.map(el => el.lang)
    const validationEnabled = true

    router.afterEach(() => {
        const appStore = useAppStore()
        appStore.redirectOverlay = false
    })

    validationEnabled && router.beforeEach(async (to, from, next) => {
        try {
            const appStore = useAppStore()
            const authStore = useAuthStore()
            const billingStore = useAccountBillingStore()
            const lang = appStore.locale || window.configs.locale
            const oldUser = authStore.user
            const extUserCookie = U_GET_COOKIE('ext_user')
            const extUser = extUserCookie && JSON.parse(extUserCookie)
            router.previousRoute = from

            if (!appStore.isLogoutOverlayOpened)
                appStore.redirectOverlay = true

            // RUN REQUESTS BEFORE FIRST PAGE LOADING START
            const isReload = from.path === '/' && (oldUser || extUser || (window.configs.user && window.configs.user.id)) && window.reload

            if (isReload) {
                DEBUG_LOG('before doRequestsBeforeLoad started')
                const { promises, success } = await doRequestsBeforeLoad({ to, routeNames })
                DEBUG_LOG('before doRequestsBeforeLoad ended', promises, ', doRequestsBeforeLoad success:', success)

                if (!success) {
                    DEBUG_LOG('doRequestsBeforeLoad failed get to ', to.path)
                    next({ path: `/${lang}/signin`, replace: true })
                }
            }
            // RUN REQUESTS BEFORE FIRST PAGE LOADING END

            // ROUTES VALIDATION START
            // if user closed password reset tab, then remove "password.reset" access, because otp code that needs for password reset request stores in session storage, and if he closed tab, otp code will be deleted. So without "password.reset" access he will be redirected to password/forgot page
            !window.sessionStorage.getItem('otp')
            && appStore.accessPages.includes('password.reset')
            && appStore.M_REMOVE_ACCESS_PAGE('password.reset')

            const user = authStore.user // user after doRequestsBeforeLoad request
            const roleObj = U_GET_AUTH_ROLE({ user })

            await U_VALIDATE_ROUTING({
                next,
                from,
                to,
                lang,
                oldUser,
                locales,
                toast: window.toast,
                hasSub: billingStore.currentSub || user?.subscription_id
            })
            // ROUTES VALIDATION END
        }
        catch (error) {
            DEBUG_ERROR('ROUTING ERROR', error)
            next({ path: '/en' })
        }
    })

    return router
}

export { createRouterFunction }
