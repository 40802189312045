import { mapActions as mapActionsPinia, mapState as mapStatePinia, mapStores as mapStoresPinia } from 'pinia'
import { U_TWO_DIGITS, U_UP_FIRST_LETTER } from 'platform-module'
import { U_GET_AUTH_ROLE } from 'auth-module'
import { useAppStore, useAuthStore } from '@/stores'
import { useModalsStore } from '@/stores/ModalsStore.js'
import { useDevicesStore } from '@/stores/DevicesStore.js'
import { useProfileStore } from '@/stores/ProfileStore.js'
import { useAccountBillingStore } from '@/stores/account/AccountBillingStore.js'
import { useAdminCustomersStore } from '@/stores/admin/AdminCustomersStore.js'
import { useAdminCountriesStore } from '@/stores/admin/AdminCountriesStore.js'
import { useAdminCouponsStore } from '@/stores/admin/AdminCouponsStore.js'
import { useAdminInvoicesStore } from '@/stores/admin/AdminInvoicesStore.js'
import { useAdminProductsStore } from '@/stores/admin/AdminProductsStore.js'
import { useAdminNotificationsStore } from '@/stores/admin/AdminNotificationsStore.js'
import { useAdminSubsStore } from '@/stores/admin/AdminSubsStore.js'
import { useAdminManagePlansStore } from '@/stores/admin/AdminManagePlansStore.js'
import { useAdminServersStore } from '@/stores/admin/AdminServersStore.js'
import { useAdminStatStore } from '@/stores/admin/AdminStatStore.js'
import { useAdminBlogStore } from '@/stores/admin/AdminBlogStore.js'
import { useAdminHelpCenterStore } from '@/stores/admin/AdminHelpCenterStore.js'
import { useAdminPagesStore } from '@/stores/admin/AdminPagesStore.js'
import { useAdminFaqStore } from '@/stores/admin/AdminFaqStore.js'

export default {
    inject: ['router', 'vm'],

    computed: {
        ...mapStatePinia(useAppStore, {
            AsideOpened: 'isAsideOpened',
            WindowWidth: 'windowWidth',
            WindowSize: 'windowSize',
            IsTouch: 'isTouchDevice',
            ContentIsLoading: 'isContentLoading'
        }),

        ...mapStatePinia(useAuthStore, { User: 'user' }),

        ...mapStatePinia(useAccountBillingStore, { CurrentSub: 'currentSub' }),

        ...mapStoresPinia(
            useAppStore,
            useAuthStore,
            useDevicesStore,
            useProfileStore,
            useModalsStore,
            useAccountBillingStore,
            useAdminCustomersStore,
            useAdminCountriesStore,
            useAdminCouponsStore,
            useAdminInvoicesStore,
            useAdminProductsStore,
            useAdminNotificationsStore,
            useAdminSubsStore,
            useAdminManagePlansStore,
            useAdminServersStore,
            useAdminStatStore,
            useAdminBlogStore,
            useAdminHelpCenterStore,
            useAdminPagesStore,
            useAdminFaqStore
        ),

        domain() {
            return window.location.origin
        },

        isAdmin() {
            const roleObj = U_GET_AUTH_ROLE({ user: this.AuthStore.user })

            return roleObj.isAdmin || roleObj.isAuthor
        },

        currencies() {
            // TODO: replace it with AppStore.Currencies
            const res = [...new Set(this.AppStore.locales.map(el => el.currency))].map((el) => {
                return { value: el, name: el }
            })

            return res
        },

        langOptions() {
            return this.AppStore.locales.length
                ? this.AppStore.locales.map((el) => {
                    return {
                        value: el.lang,
                        name: el.name,
                        icon: `Flag${U_UP_FIRST_LETTER(el.lang)}`
                    }
                })
                : []
        }
    },

    methods: {
        ...mapActionsPinia(useAppStore, { toggleLoader: 'toggleLoader' }),

        ...mapActionsPinia(useModalsStore, { toggleModal: 'toggleModal' }),

        debounce(func, timeout = 0) {
            return (...args) => {
                clearTimeout(this.searchTimeout)
                this.searchTimeout = setTimeout(() => func.apply(this, args), timeout)
            }
        },

        filterErrors({ target, form, arr }) {
            function toObject(pairs) {
                return Array.from(pairs).reduce((acc, [key, value]) => Object.assign(acc, { [key]: value }), {})
            }

            Object.filter = (obj, predicate) => toObject(Object.entries(obj).filter(predicate))

            return Object.filter(target, ([key]) => (form
                ? this[form].includes(key)
                : arr.includes(key)))
        },

        validateFields({ data, key, arr }) {
            const filteredErrors = this.filterErrors({
                target: data,
                form: key,
                arr
            })
            const errors = []
            for (const key in filteredErrors) {
                if (!data[key].valid) {
                    errors.push(key)
                    data[key].error = true
                }
            }

            return !errors.length
        },

        onInput(objNameOrObj, key, e, validation = true) {
            const val = e.target.value

            if (typeof objNameOrObj === 'string') {
                if (validation || val) {
                    this[objNameOrObj][key].error = !val
                    this[objNameOrObj][key].valid = !!val
                }

                if (!val) {
                    this[objNameOrObj][key].message = this.trans('input_errors.field_is_required')
                }

                this[objNameOrObj][key].value = val
            }
            else {
                if (validation || val) {
                    objNameOrObj[key].error = !val
                    objNameOrObj[key].valid = !!val
                }

                if (!val) {
                    objNameOrObj[key].message = this.trans('input_errors.field_is_required')
                }

                objNameOrObj[key].value = val
            }
        },

        validateEmail(obj, key, e) {
            const val = e
                ? e.target.value
                : this[obj][key].value
            const validRegex = /^[\w-., +]+@([\w-]+\.)+[\w-]{2,4}$/
            const match = val.match(validRegex)
            const isValid = (match && !!match.length) || false

            if (val && val.length) {
                this[obj][key].error = !isValid
                this[obj][key].valid = isValid
                this[obj][key].message = this.trans('input_errors.email_is_incorrect')
            }

            return this[obj][key].valid
        },

        inputOnFocus(objNameOrObj, key) {
            if (typeof objNameOrObj === 'string') {
                this[objNameOrObj][key].focused = true
            }
            else {
                objNameOrObj[key].focused = true
            }
        },

        inputOnBlur(objNameOrObj, key) {
            if (typeof objNameOrObj === 'string') {
                this[objNameOrObj][key].focused = false
            }
            else {
                objNameOrObj[key].focused = false
            }
        },

        trans(key) {
            function getValue(object, path) {
                return path
                    .replace(/\[/g, '.')
                    .replace(/\]/g, '')
                    .split('.')
                    .reduce((o, k) => (o || {})[k], object)
            }

            return getValue(this.AppStore.translations, key) || key
        },

        initInputs(obj) {
            for (const key in obj) {
                if (obj[key].value)
                    obj[key].valid = true
            }
        },

        showErrors(error, objKey, that) {
            this.$nextTick(() => {
                if (error.response && error.response.data) {
                    const { errors_validation, errors_info } = error.response.data

                    if (errors_validation && that) {
                        for (const key in errors_validation) {
                            if (Object.prototype.hasOwnProperty.call(that[objKey], key)) {
                                that[objKey][key].message = errors_validation[key][0]
                                that[objKey][key].error = true
                                that[objKey][key].valid = false
                            }
                            else {
                                console.log(errors_validation[key][0])
                            }
                        }
                    }
                    else if (errors_info && that) {
                        that.error = { value: true, message: errors_info[0] }
                    }
                    else {
                        window.toast.add({ type: 'destructive', text1: errors_info[0] })
                    }
                }
                else {
                    console.log(error)
                }
            })
        },

        toggleInputError({ obj, key, boolean, errorMessage }) {
            this[obj][key].error = boolean
            this[obj][key].valid = !boolean
            this[obj][key].message = errorMessage
        },

        resetInputs(inputsKey, keys) {
            keys.forEach((key) => {
                if (Object.prototype.hasOwnProperty.call(this[inputsKey][key], 'valid'))
                    this[inputsKey][key].valid = false

                if (Object.prototype.hasOwnProperty.call(this[inputsKey][key], 'valid'))
                    this[inputsKey][key].error = false

                if (Object.prototype.hasOwnProperty.call(this[inputsKey][key], 'valid'))
                    this[inputsKey][key].value = null
            })
        },

        deleteEmptyKeys(obj, exceptions = []) {
            const result = obj
            for (const key in result) {
                if (!obj[key] && !exceptions.includes(key)) {
                    delete obj[key]
                }
            }

            return result
        },

        isCardExpired({ exp_month, exp_year }) {
            const cardEndDate = this.$moment(`${exp_year}-${U_TWO_DIGITS(exp_month)}-01T00:00:00`).local()
            const nowDate = this.$moment().local()

            return nowDate.unix() > cardEndDate.unix()
        },

        transformCreditCards(cards, card_id) {
            cards.map((card) => {
                card.default = card.card_id == card_id

                return card
            })

            return [...cards.filter(el => el.default), ...cards.filter(el => !el.default)]
        },

        getNestedObjectValue(obj, path) {
            if (obj) {
                const keys = path.split(/[[\].]+/).filter(Boolean)
                let current = obj

                for (const key of keys) {
                    if (Object.prototype.hasOwnProperty.call(current, key) || (Array.isArray(current) && !Number.isNaN(key))) {
                        current = current[key]
                    }
                    else {
                        return undefined // Path does not exist in the object
                    }
                }

                return current
            }
            else {
                return path
            }
        },

        // TODO: remove after removing old AppHeader component at all pages
        changeLang(lang) {
            const currentPath = this.router.currentRoute.value.path
            const languageDetector = new RegExp(`^/(${this.AppStore.locales.map(el => el.lang).join('|')})?/?`)
            const newPath = currentPath.replace(languageDetector, `/${lang}/`)
            this.AppStore.SET_LANGUAGE({ payload: { lang } })

            if (currentPath !== newPath)
                this.router.push({ path: newPath })
        }
    }
}
