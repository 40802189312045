<template>
    <AppModal
        icon-type="warning" :opened="opened" :toggle-page-body-scroll="togglePageBodyScroll"
        :modals-store="ModalsStore" @on-close="cancel"
    >
        <template #content>
            <div class="modal-content-inner">
                <h3 class="h3">
                    {{ title }}
                </h3>
                <p class="alert text-size-s">
                    {{ text }}
                </p>

                <form v-if="passwordConfirmation" autocomplete="off" @submit.prevent="confirm">
                    <AppInput
                        ref="passwordInput"
                        :default-error-message="trans('input_errors.field_is_required')"
                        prop-id="password"
                        label="Enter your account password"
                        :placeholder="trans('inputs.password_placeholder')"
                        :props="inputs.password"
                        autocomplete="on"
                        @on-input="onInput('inputs', 'password', $event), (yesButton.disabled = false)"
                        @on-focus="inputOnFocus('inputs', 'password', $event)"
                        @on-blur="inputOnBlur('inputs', 'password', $event)"
                        @on-type-change="inputs.password.type = $event"
                    >
                        <template #icon>
                            <FAlock class="icon icon--blue icon--fluid" />
                        </template>
                        <template #iconPasswordVisible>
                            <FAeye class="icon icon--dark icon--fluid" />
                        </template>
                        <template #iconPasswordHidden>
                            <FAeyeSlash class="icon icon--dark icon--fluid" />
                        </template>
                    </AppInput>
                </form>
            </div>

            <div class="btn-row">
                <AppButton
                    ref="noButton"
                    :label="noButton.label"
                    size="m"
                    width="auto"
                    :class="noButton.class"
                    :loader-color="noButton.loaderColor"
                    :disabled="noButton.disabled"
                    @click="cancel"
                />
                <AppButton
                    ref="yesButton"
                    :label="yesButton.label"
                    size="m"
                    width="auto"
                    :class="yesButton.class"
                    :disabled="yesButton.disabled"
                    :loading="yesButton.loading"
                    loader-color="#fff"
                    @click="confirm"
                />
            </div>
        </template>
    </AppModal>
</template>

<script>
export default {
    name: 'ModalConfirmation',

    data() {
        return {
            opened: false,
            title: 'Confirmation title',
            text: 'Confirmation text',
            actionName: '',
            callbackData: null,
            iconType: 'warning',

            noButton: {
                label: 'No',
                class: 'ep-btn--lightgrey min-width-auto',
                loaderColor: '#fff',
                loading: false,
                disabled: false
            },

            yesButton: {
                label: 'Yes',
                class: 'ep-btn--error min-width-auto',
                loaderColor: '#fff',
                loading: false,
                disabled: false
            },

            inputs: {
                password: {
                    valid: false,
                    error: false,
                    value: null,
                    type: 'password',
                    message: 'Error'
                }
            },

            passwordConfirmation: false,
            resolvePromise: undefined,
            rejectPromise: undefined,
            yesCallback: null,
            noCallback: null,
            togglePageBodyScroll: true
        };
    },

    watch: {
        opened(val) {
            if (!val) {
                this.passwordConfirmation && this.resetInputs('inputs', [ 'password' ]);
                this.noButton.loading = false;
                this.yesButton.loading = false;
            }
        }
    },

    methods: {
        setPromise() {
            return new Promise((resolve, reject) => {
                this.resolvePromise = resolve;
                this.rejectPromise = reject;
            });
        },

        open(options) {
            const { title, text, noButton, yesButton, actionName, iconType, passwordConfirmation, yesCallback, noCallback, callbackData, togglePageBodyScroll } = options;
            this.title = title || this.title;
            this.text = text || this.text;
            this.noButton = noButton
                ? { ...this.noButton, ...noButton }
                : this.noButton;
            this.yesButton = yesButton
                ? { ...this.yesButton, ...yesButton }
                : this.yesButton;
            this.iconType = iconType || this.iconType;
            this.passwordConfirmation = passwordConfirmation;
            this.actionName = actionName;
            this.callbackData = callbackData;
            this.yesCallback = yesCallback;
            this.noCallback = noCallback;
            this.togglePageBodyScroll = togglePageBodyScroll;
            this.opened = true;

            // Return promise so the caller can get results
            return this.setPromise();
        },

        hide() {
            this.opened = false;
        },

        cancel() {
            this.noCallback && this.noCallback(this.callbackData);
            this.hide();
            this.resolvePromise({ boolean: false, actionName: this.actionName });
        },

        showPasswordError(message) {
            this.inputs.password.error = true;
            this.inputs.password.message = message;
            this.inputs.password.valid = false;
        },

        async confirm() {
            if (this.passwordConfirmation) {
                this.validateFields({ data: this.inputs, arr: [ 'password' ] }) && this.yesCallback && this.yesCallback(this.callbackData);
            }
            else if (this.yesCallback) {
                this.yesCallback(this.callbackData);
            }
            else {
                this.yesButton.loading = true;
                this.resolvePromise({ boolean: true, actionName: this.actionName });
            }
        }
    }
};
</script>
