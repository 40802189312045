import { BREAKPOINTS, DEBUG_LOG, U_CATCH_ERROR } from 'platform-module'
import { U_GET_AUTH_ROLE, U_SET_AUTH_COOKIE } from 'auth-module'
import { useAppStore, useAuthStore, useNewAccountBillingStore } from '@/stores'
import { MODULES_BOOLEANS } from '@/static'

/**
 * Listens to events from the browser extension and handles corresponding actions.
 * If 'BroadcastChannel' is supported in the browser, it listens for messages from other tabs
 * using a BroadcastChannel named 'epicvpn_website_channel'.
 * It also listens for messages from the extension using the 'message' event.
 *
 * @param {object} options - Options object.
 * @param {object} options.router - Vue router instance.
 */
export function LISTEN_BROWSER_EXTENSION_EVENTS({ router }) {
    if ('BroadcastChannel' in window) {
        const storeAuth = useAuthStore()
        window.epicWebSiteChannel = new BroadcastChannel('epicvpn_website_channel')

        // Listen for messages from other tabs
        window.epicWebSiteChannel.onmessage = async function (event) {
            try {
                const action = event.data.action
                DEBUG_LOG('Message received in tab:', event.data)

                if (action === 'LOGOUT' && storeAuth.user) {
                    await storeAuth.LOGOUT({ is_overlay_enabled: false })
                    await router.push({ name: 'signin' })
                }
            }
            catch (error) {
                DEBUG_LOG('%cBroadcastChannel ERROR', 'color:red', error)
            }
        }
    }

    // CATCH ACTION FROM EXTENSION START
    window.addEventListener('message', async ({ data: { type, action } }) => {
        try {
            const storeAuth = useAuthStore()
            const storeApp = useAppStore()

            const { M_HANDLE_ACCESS_PAGES } = storeApp
            const { GET_CURRENT_SUB } = useNewAccountBillingStore()
            const { AUTH_CHECK, LOGOUT } = storeAuth

            if (type === 'ACTION_FROM_EXTENSION' && action === 'LOGIN') {
                const { user } = await AUTH_CHECK()
                const breakpoint = U_GET_AUTH_ROLE({ user }).isAdmin
                    ? BREAKPOINTS.ASIDE_ADMIN
                    : BREAKPOINTS.ASIDE_USER
                storeApp.isAsideOpened = window.innerWidth >= breakpoint
                M_HANDLE_ACCESS_PAGES({ user, routeNames: window.routeNames })
                await router.push({ name: 'dashboard' })

                if (user.activated && !user.delete_at)
                    await GET_CURRENT_SUB.fn({ modulesBooleans: MODULES_BOOLEANS })

                U_SET_AUTH_COOKIE(true)
            }
            else if (type === 'ACTION_FROM_EXTENSION' && action === 'LOGOUT' && storeAuth.user) {
                await LOGOUT({ is_overlay_enabled: false })
                await router.push({ name: 'signin' })
                window.epicWebSiteChannel.postMessage({ action: 'LOGOUT' })
            }
        }
        catch (error) {
            U_CATCH_ERROR(error)
        }
    })
    // CATCH ACTION FROM EXTENSION END
}
