<template>
    <router-link :to="{ path: `/${AppStore.locale}/` }" class="logo link link--unstyled">
        <svg width="454" height="126" viewBox="0 0 454 126" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1709_9)">
                <path d="M156.66 42.1599V51.4499H126.09V57.9999H155.51V67.2899H126.09V73.8299H156.66V83.1199H114.22V42.1599H156.66Z" fill="#0E57CC" />
                <path
                    d="M207.7 46.1999C210.6 48.8666 212.053 52.5866 212.06 57.3599C212.067 62.1332 210.613 65.8699 207.7 68.5699C204.787 71.2699 200.85 72.6032 195.89 72.5699H175.58V83.0699H163.71V42.1599H195.89C200.863 42.1599 204.8 43.5066 207.7 46.1999ZM192.62 62.5199C193.839 62.539 195.056 62.452 196.26 62.2599C197.195 62.0877 198.044 61.6049 198.67 60.8899C199.421 59.8738 199.777 58.619 199.67 57.3599C199.773 56.1198 199.417 54.8851 198.67 53.8899C198.047 53.1687 197.197 52.6818 196.26 52.5099C195.056 52.3178 193.839 52.2308 192.62 52.2499H175.62V62.5199H192.62Z"
                    fill="#0E57CC"
                />
                <path d="M218.48 42.1599H230.35V83.1599H218.48V42.1599Z" fill="#0E57CC" />
                <path
                    d="M243.34 46.55C247.72 42.85 254.02 41 262.24 41C269.66 41 275.5 42.5033 279.76 45.51C281.83 46.9269 283.56 48.786 284.824 50.9529C286.088 53.1198 286.855 55.5405 287.07 58.04H274.46C274.222 56.9925 273.766 56.0065 273.124 55.1454C272.482 54.2843 271.666 53.5671 270.73 53.04C268.657 51.7067 265.827 51.04 262.24 51.04C257.92 51.04 254.67 52.04 252.49 54.04C250.31 56.04 249.22 58.87 249.22 62.53C249.22 66.2033 250.32 69.05 252.52 71.07C254.72 73.09 257.96 74.09 262.24 74.07C265.827 74.07 268.657 73.4033 270.73 72.07C271.67 71.5304 272.487 70.8009 273.129 69.9279C273.771 69.055 274.225 68.0578 274.46 67H287.07C286.857 69.5124 286.092 71.9466 284.828 74.1284C283.564 76.3102 281.834 78.1855 279.76 79.62C275.5 82.64 269.66 84.15 262.24 84.15C254.02 84.15 247.72 82.2933 243.34 78.58C238.96 74.8667 236.773 69.4967 236.78 62.47C236.78 55.5433 238.967 50.2367 243.34 46.55Z"
                    fill="#0E57CC"
                />
                <path d="M321.43 80.3L343.51 43H347.35L323.43 83.11H319.36L295.51 43H299.29L321.43 80.3Z" fill="#0E57CC" />
                <path
                    d="M390.59 44.6301C392.859 45.6475 394.777 47.3142 396.1 49.4201C397.438 51.6413 398.114 54.1979 398.05 56.7901C398.113 59.3821 397.437 61.9385 396.1 64.1601C394.777 66.2778 392.861 67.9607 390.59 69.0001C387.981 70.1783 385.142 70.7559 382.28 70.6901H357.73V83.1101H354.41V43.0001H382.28C385.136 42.9252 387.974 43.4817 390.59 44.6301V44.6301ZM381.36 67.5701C384.712 67.7067 388.025 66.8095 390.85 65.0001C393.317 63.2934 394.55 60.5501 394.55 56.7701C394.55 52.9901 393.317 50.2467 390.85 48.5401C388.024 46.7364 384.709 45.8493 381.36 46.0001H357.73V67.5701H381.36Z"
                    fill="#0E57CC"
                />
                <path d="M449.9 79.9V43H453.22V83.11H448.52L410.21 46.18V83.11H406.88V43H411.59L449.9 79.9Z" fill="#0E57CC" />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M77.59 0.82C56.99 6.82 41.82 22.82 38.79 42.11C20.16 50.17 1.92 59.86 0 89.21C9.57 70.15 22.7 69.71 37.45 68C38.67 67.86 39.9 67.7 41.12 67.54L41.79 67.43C41.88 67.64 41.95 67.85 42.04 68.06C42.53 69.31 43.04 70.53 43.57 71.73C49.41 85.27 55.44 96.83 43.78 114.53C68.24 98.19 67.53 77.53 65.17 57.38C81.16 44.45 87.26 21.82 80.7 0C80.4333 11.92 75.0733 21.7067 64.62 29.36C73.6267 20.14 77.95 10.6267 77.59 0.82Z"
                    fill="#0E57CC"
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M80.7 0C80.8 0.34 80.89 0.69 81 1C82.4106 5.98874 83.1801 11.1368 83.29 16.32V17C83.29 33.37 75.88 47.74 62.29 56.25C45 67.11 29.39 62.49 14.26 73.32C9 76.2 4.14 81 0 89.21C0.58 80.27 2.8 71.57 7.85 64.06C15.27 53 27 47.24 38.79 42.11V42.05C48.08 38 57.45 34.42 65 29L64.64 29.36C74.33 22.26 80.4 12.71 80.7 0Z"
                    fill="#B1BBC9"
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M9.56995 125.27C9.93995 118.96 11.3299 107.69 15.0899 97.9201C26.5599 68.1501 50.5399 82.0001 30.4899 106.81C23.9099 114.95 14.8399 121.81 9.56995 125.27V125.27Z"
                    fill="#0E57CC"
                />
            </g>
            <defs>
                <clipPath id="clip0_1709_9">
                    <rect width="453.22" height="125.27" fill="white" />
                </clipPath>
            </defs>
        </svg>
    </router-link>
</template>
<script>
export default {
    name: "AppLogo",
};
</script>
