import type { ComputedRef } from 'vue'
import { computed, reactive } from 'vue'
import { defineStore } from 'pinia'

export const useDebugInfoStore = defineStore(
    'DebugInfo',
    () => {
        const debugInfoBooleans = reactive({
            breakpoints: {
                opened: true,
                val: true
            },
            containerBorder: {
                opened: false,
                val: true
            }
        })

        function $reset() {
            for (const key in debugInfoBooleans) {
                debugInfoBooleans[key as keyof typeof debugInfoBooleans] = {
                    val: false,
                    opened: false
                }
            }
        }

        const HasEnabledValues: ComputedRef<boolean> = computed(() => {
            return Object.values(debugInfoBooleans).map(el => el.val).filter(el => el)?.length > 0
        })

        return { HasEnabledValues, debugInfoBooleans, $reset }
    },
    { persist: true }
)
