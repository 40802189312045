<template>
    <AppSelect
        :options="optionsProp.length ? optionsProp : selectData"
        :option="option"
        :dropdown-align="dropdownAlign"
        :background="background"
        :option-padding="'0 10px'"
        :label-text="labelText"
        dropdown-offset="-4px"
        @on-select="
            option = $event;
            $emit('on-select', $event);
        "
    >
        <template #header="{ header }">
            <template v-if="header && header.icon">
                <component :is="header.icon" class="icon w-22px h-14px"></component>
            </template>
        </template>
        <template #option="{ item }">
            <div class="flag mr-6px">
                <component :is="item.icon"></component>
            </div>
            {{ item.name }}
        </template>
    </AppSelect>
</template>

<script>
import { U_UP_FIRST_LETTER } from "platform-module";

export default {
    name: "LanguageSelect",

    props: {
        dropdownAlign: {
            type: String,
            default: "start",
        },

        background: {
            type: String,
            default: "#fff",
        },

        labelText: {
            type: Boolean,
            default: true,
        },

        optionProp: {
            type: Object,
            default: () => null,
        },

        optionsProp: {
            type: Array,
            default: () => [],
        },
    },

    emits: ["on-select"],

    data() {
        return {
            option: {},
        };
    },

    computed: {
        selectData() {
            return (
                this.AppStore.locales.length &&
                this.AppStore.locales.map((el) => {
                    return { value: el.lang, name: el.name, icon: `Flag${U_UP_FIRST_LETTER(el.lang)}` };
                })
            );
        },

        currentIndex() {
            return this.AppStore.locales.length && this.AppStore.locales.findIndex((el) => el.lang == this.AppStore.locale);
        },
    },

    mounted() {
        this.option = this.optionProp || this.selectData[this.currentIndex];
    },
};
</script>
