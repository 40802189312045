<template>
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M14.0035 11.0741C13.5699 11.0214 13.1304 10.9628 12.6382 11.0741C11.6011 11.3495 10.8394 12.2753 10.564 13.6405C10.3472 14.5663 10.3999 15.7147 10.7281 16.5878C11.1617 17.953 12.146 18.7147 13.3472 18.662C14.5484 18.662 15.5269 17.9003 15.9663 16.5878C16.2417 15.7147 16.2945 14.6249 16.1304 13.6405C15.8081 12.2753 15.0406 11.3436 14.0035 11.0741Z"
            fill="#767676"
        />
        <path
            d="M15 0C6.70898 0 0 6.70898 0 15C0 23.291 6.70898 30 15 30C23.291 30 30 23.291 30 15C30 6.70898 23.291 0 15 0ZM7.4707 20.1797H5.56641V12.6562H7.47656V20.1797H7.4707ZM6.49219 11.5664C5.94727 11.5664 5.45508 11.1328 5.45508 10.5293C5.45508 9.98437 5.88867 9.49219 6.49219 9.49219C7.03711 9.49219 7.5293 9.92578 7.5293 10.5293C7.5293 11.0742 7.08984 11.5664 6.49219 11.5664ZM15.4922 19.7461C15 19.9629 14.5664 20.0742 13.8574 20.1797C13.5293 20.2324 13.1484 20.1797 12.8203 20.1797C12.1113 20.0684 11.6191 19.9629 11.1855 19.7461C9.43945 18.9258 8.51367 17.1797 8.51367 14.8887C8.51367 12.5977 9.49805 10.7988 11.2383 9.97852C11.8945 9.65039 12.6035 9.48633 13.3652 9.48633C14.1855 9.48633 14.8359 9.65039 15.4922 9.97852C17.1855 10.8516 18.2168 12.6504 18.2168 14.8887C18.2168 17.1797 17.2383 18.9258 15.4922 19.7461ZM25.2012 18.3281C24.709 19.3652 23.7305 20.0215 22.2012 20.1855C21.9258 20.2383 21.2754 20.2383 21 20.1855C20.1797 20.0215 19.3652 19.9102 18.709 19.5293L19.1426 18C20.2324 18.3809 21.7617 18.873 22.7988 18.4336C23.3438 18.1582 23.6191 17.6133 23.5605 17.0156C23.4492 16.3594 23.0156 15.9785 21.7031 15.4863C20.2852 14.9414 19.4648 14.2852 19.084 13.4707C18.9199 13.0371 18.8672 12.5449 18.9199 12.0527C19.1367 10.7461 20.1211 9.87305 21.7031 9.59766C22.1367 9.54492 23.1211 9.54492 23.5605 9.59766C24.2168 9.70898 25.0313 9.97852 25.0313 10.0898L24.5977 11.5605C23.9414 11.2852 23.1797 11.127 22.5234 11.127C21.5977 11.127 20.666 11.6719 20.8301 12.5977C20.9414 13.1953 21.3223 13.4707 22.7402 14.0684C23.7773 14.502 24.2695 14.7773 24.7031 15.2109C25.3008 15.8086 25.4648 16.4648 25.4648 17.1738C25.418 17.6191 25.3652 17.8887 25.2012 18.3281Z"
            fill="#767676"
        />
    </svg>
</template>
