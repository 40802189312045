export enum Breakpoints {
    SM = 'sm',
    MD = 'md',
    LG = 'lg'
}

export enum MaxBreakpointStates {
    OPENED = 'OPENED',
    CLOSED = 'CLOSED'
}

export type BreakpointRanges = readonly [number, number]

interface MaxBreakpointObj {
    [MaxBreakpointStates.OPENED]: Record<Breakpoints, BreakpointRanges>
    [MaxBreakpointStates.CLOSED]: Record<Breakpoints, BreakpointRanges>
}

export const MAX_BREAKPOINT_OBJ: MaxBreakpointObj = {
    [MaxBreakpointStates.OPENED]: {
        [Breakpoints.LG]: [1324, 9000],
        [Breakpoints.MD]: [1068, 1323],
        [Breakpoints.SM]: [0, 1067]
    },
    [MaxBreakpointStates.CLOSED]: {
        [Breakpoints.LG]: [1096, 9000],
        [Breakpoints.MD]: [840, 1095],
        [Breakpoints.SM]: [0, 839]
    }
} as const
