import { createVNode, render } from 'vue'
import { NewLoader } from 'vue-components2' // Adjust the import path to your Loader component
import type { DirectiveBinding, VNode } from 'vue'

/**
 * vLoading directive to show a loading spinner while hiding content.
 *
 * This directive displays a loader and hides the content when `binding.value` is `true`.
 * When `binding.value` becomes `false`, the loader is removed, and the content is shown again.
 */

// WeakMap to track loader VNodes
const loaderMap = new WeakMap<HTMLElement, VNode>()

function RENDER_LOADER(el: HTMLElement) {
    const newLoaderVNode = createVNode(NewLoader, { size: '3xl', class: 'v-loader' })
    render(newLoaderVNode, el)
    loaderMap.set(el, newLoaderVNode)
    el.classList.add('v-loader-hidden-content')
}

const vLoading = {
    /**
     * Called when the directive is first bound to the element.
     *
     * @param el - The element the directive is bound to.
     * @param binding - An object containing the current value of the directive.
     */
    mounted(el: HTMLElement, binding: DirectiveBinding<boolean>) {
        binding.value && RENDER_LOADER(el)
    },

    /**
     * Called when the bound element's parent component and the directive are updated.
     *
     * @param el - The element the directive is bound to.
     * @param binding - An object containing the current value of the directive.
     */
    updated(el: HTMLElement, binding: DirectiveBinding<boolean>) {
        const loaderVNode = loaderMap.get(el)

        if (binding.value) {
            !loaderVNode && RENDER_LOADER(el)
        }
        else {
            if (loaderVNode) {
                render(null, el)

                el.classList.remove('v-loader-hidden-content')
                el.classList.add('v-loader-hidden-content-show')

                setTimeout(() => el.classList.remove('v-loader-hidden-content-show'), 1000)

                loaderMap.delete(el)
            }
        }
    },

    /**
     * Called when the directive is unbound from the element.
     *
     * @param el - The element the directive is bound to.
     */
    unmounted(el: HTMLElement) {
        const loaderVNode = loaderMap.get(el)

        if (loaderVNode) {
            render(null, el)
            loaderMap.delete(el)
        }
    }
}

export default vLoading
