<template>
    <svg width="2232" height="2500" viewBox="0 0 2232 2500" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1386_46)">
            <path
                d="M764.007 1518.52C850.187 1518.52 920.052 1588.38 920.052 1674.56V2343.95C920.052 2430.14 850.187 2499.99 764.007 2499.99C677.838 2499.99 607.973 2430.14 607.973 2343.95V1674.56C607.973 1588.39 677.838 1518.53 764.007 1518.53V1518.52Z"
                fill="#767676"
            />
            <path
                d="M407.769 713.725C407.254 719.642 406.805 725.625 406.805 731.674V1745.86C406.805 1854.93 493.138 1942.8 600.313 1942.8H1631.67C1738.86 1942.8 1825.19 1854.92 1825.19 1745.86V731.674C1825.19 725.625 1824.97 719.62 1824.47 713.725H407.769Z"
                fill="#767676"
            />
            <path
                d="M1468 1518.52C1554.18 1518.52 1624.05 1588.38 1624.05 1674.56V2343.95C1624.05 2430.14 1554.18 2499.99 1468 2499.99C1381.83 2499.99 1311.97 2430.14 1311.97 2343.95V1674.56C1311.97 1588.39 1381.83 1518.53 1468 1518.53V1518.52ZM156.088 803.982C242.258 803.982 312.122 873.847 312.122 960.027V1629.42C312.122 1715.6 242.258 1785.46 156.088 1785.46C69.9077 1785.46 0.04297 1715.6 0.04297 1629.43V960.038C0.0320126 873.858 69.8968 803.982 156.088 803.982ZM2075.93 803.982C2162.1 803.982 2231.97 873.847 2231.97 960.027V1629.42C2231.97 1715.6 2162.1 1785.46 2075.93 1785.46C1989.75 1785.46 1919.89 1715.6 1919.89 1629.43V960.038C1919.89 873.858 1989.75 803.982 2075.93 803.982V803.982ZM412.328 647.104C416.668 335.583 688.566 80.1647 1037.58 46.5034H1194.41C1543.45 80.1757 1815.32 335.605 1819.66 647.104H412.328Z"
                fill="#767676"
            />
            <path
                d="M466.6 16.4362L631.203 301.549M1765.42 16.4362L1600.81 301.549"
                stroke="#767676"
                stroke-width="32.8724"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M906.378 367.93C906.51 411.475 870.471 446.878 825.874 446.999C781.299 447.108 745.052 411.913 744.92 368.368V367.93C744.8 324.374 780.839 288.981 825.425 288.861C870 288.74 906.247 323.924 906.378 367.491V367.93ZM1509 367.93C1509.13 411.475 1473.09 446.878 1428.49 446.999C1383.92 447.108 1347.67 411.913 1347.54 368.368V367.93C1347.42 324.374 1383.46 288.981 1428.04 288.861C1472.62 288.74 1508.86 323.924 1509 367.491V367.93Z"
                fill="white"
            />
        </g>
        <defs>
            <clipPath id="clip0_1386_46">
                <rect width="2232" height="2500" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>
