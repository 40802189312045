import { computed, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import type { RouteLocationRaw, Router } from 'vue-router'
import type { ComputedRef, Ref } from 'vue'

import { U_CATCH_ERROR, U_WAIT, trans } from 'platform-module'
import { useAuthUser } from 'auth-module'
import { ROLE_NAME } from 'auth-module/src/static/enums'

import { ENUMS } from 'vue-components2'
import { MODULES_BOOLEANS } from '@/static'
import { useAppStore, useAuthStore, useNewAccountBillingStore } from '@/stores'

import type {
    AsideChildItem,
    AsideHtmlReturn,
    AsideInitialItem,
    AsideItem
} from '@/components/PageWrapper/types'

export function usePageWrapper({
    props,
    asideHtmlEl,
    IsTablet,
    IsDesktop
}: {
    props: any
    asideHtmlEl: Ref<AsideHtmlReturn>
    IsTablet: Ref<boolean>
    IsDesktop: Ref<boolean>
}) {
    const router: Router = useRouter()
    const route = useRoute()
    const appStore = useAppStore()
    const authStore = useAuthStore()
    const billingStore = useNewAccountBillingStore()
    const burgerIsActive: Ref<boolean> = ref(false)
    const { ROLE, isAdminOrAuthor } = useAuthUser({ user: authStore.user })
    type NavObj = Record<ROLE_NAME, AsideInitialItem[]>

    const footerNavList = [
        { label: trans(appStore.translations.footer, 'terms_of_service'), to: { name: 'signin' } },
        { label: trans(appStore.translations.footer, 'privacy_policy'), to: { name: 'signin' } }
    ]

    async function ROUTE_CLICK(item: any) {
        await router.push({ name: item.to.name })
    }

    function BURGER_ON_TOGGLE(isActive: boolean) {
        burgerIsActive.value = isActive
    }

    function GET_USER_ASIDE_NAV_ITEMS(): AsideInitialItem[] {
        return [
            {
                iconClassName: 'common-aside-dashboard',
                label: trans(appStore.translations.user_sidebar_menu, 'dashboard'),
                to: { name: 'dashboard' },
                sort: 1
            },
            {
                iconClassName: 'common-aside-account',
                label: trans(appStore.translations.user_sidebar_menu, 'account'),
                to: { name: 'account.profile' },
                sort: 2
            },
            {
                iconClassName: 'common-aside-data-monitor',
                label: trans(appStore.translations.user_sidebar_menu, 'breach_monitor'),
                to: { name: 'home' },
                sort: 4
            },
            {
                iconClassName: 'common-aside-refer-friend',
                label: trans(appStore.translations.user_sidebar_menu, 'refer_friend'),
                to: { name: 'home' },
                sort: 6
            },
            {
                iconClassName: 'common-aside-downloads',
                label: trans(appStore.translations.user_sidebar_menu, 'download'),
                to: { name: 'home' },
                sort: 7
            },
            {
                iconClassName: 'common-aside-faq',
                label: trans(appStore.translations.user_sidebar_menu, 'help_center'),
                to: { name: 'home' },
                sort: 8
            },
            {
                iconClassName: 'common-aside-support',
                label: trans(appStore.translations.user_sidebar_menu, 'support'),
                to: { name: 'home' },
                sort: 8
            }
        ]
    }

    function GET_ADMIN_ASIDE_NAV_ITEMS(): AsideInitialItem[] {
        return [
            {
                iconClassName: 'common-admin-dashboard',
                label: 'Dashboard',
                to: { name: 'admin.dashboard' },
                sort: 1
            },
            {
                iconClassName: 'common-aside-customers',
                label: 'Customers',
                to: { name: 'admin.customers' },
                sort: 2
            },
            {
                iconClassName: 'common-aside-statistics',
                label: 'Statistics',
                children: [
                    {
                        label: 'Earnings',
                        to: { name: 'admin.statistics.earnings.overview' }
                    },
                    {
                        label: 'Subscriptions',
                        to: { name: 'admin.statistics.subscriptions.overview' }
                    },
                    {
                        label: 'Analytics',
                        to: { name: 'admin.statistics.analytics' }
                    }
                ],
                sort: 3
            },
            {
                iconClassName: 'common-aside-servers',
                label: 'Servers',
                children: [
                    {
                        label: 'Countries',
                        to: { name: 'admin.servers.countries' }
                    },
                    {
                        label: 'Servers List',
                        to: { name: 'admin.servers.servers-list' }
                    },
                    {
                        label: 'All Servers',
                        to: { name: 'admin.servers.all-servers' }
                    }
                ],
                sort: 4
            },
            {
                iconClassName: 'common-aside-payment-gateways',
                label: 'Payment Gateways',
                children: [
                    {
                        label: 'Stripe',
                        to: { name: 'admin.payment-gateways.stripe.products' }
                    },
                    {
                        label: 'Paypal',
                        to: { name: 'admin.payment-gateways.paypal.products' }
                    },
                    {
                        label: 'IAP',
                        to: { name: 'admin.payment-gateways.iap.products' }
                    },
                    {
                        label: 'Cryptomus',
                        to: { name: 'admin.payment-gateways.cryptomus.products' }
                    },
                    {
                        label: 'Add-ons',
                        to: { name: 'admin.payment-gateways.addons.modules' }
                    }
                ],
                sort: 5
            },
            {
                iconClassName: 'common-aside-addons',
                label: 'Addons',
                to: { name: '' },
                sort: 6,
                children: [] // TODO: add pages
            },
            {
                iconClassName: 'common-aside-edit-plan',
                label: 'Manage Plans',
                to: { name: 'admin.order.manage-plans.stripe' },
                sort: 7
            },
            {
                iconClassName: 'common-aside-coupon',
                label: 'Coupons',
                to: { name: 'admin.coupons.overview' },
                sort: 8
            },
            {
                iconClassName: 'common-aside-blog',
                label: 'Blog',
                children: [
                    {
                        label: 'Posts',
                        to: { name: 'admin.blog.posts.overview' }
                    },
                    {
                        label: 'Categories',
                        to: { name: 'admin.blog.categories.overview' }
                    }
                ],
                sort: 9
            },
            {
                iconClassName: 'common-aside-blog',
                label: 'Help Center',
                children: [
                    {
                        label: 'Articles',
                        to: { name: 'admin.help-center.articles.overview' }
                    },
                    {
                        label: 'Categories',
                        to: { name: 'admin.help-center.categories.overview' }
                    }
                ],
                sort: 10
            },
            {
                iconClassName: 'common-aside-pages',
                label: 'Pages',
                to: { name: 'admin.pages.overview' },
                sort: 11
            },
            {
                iconClassName: 'common-aside-faq',
                label: 'FAQ',
                children: [
                    {
                        label: 'FAQ List',
                        to: { name: 'admin.faq.list.overview' }
                    }
                ],
                sort: 12
            },
            {
                iconClassName: 'common-aside-static-pages',
                label: 'Static Pages',
                to: { name: 'admin.staticPages.overview' },
                sort: 13
            },
            {
                iconClassName: 'common-aside-oauth',
                label: 'OAuth',
                children: [
                    {
                        label: 'Server',
                        to: { name: 'admin.oauth.clients' }
                    },
                    {
                        label: 'Client',
                        to: { name: 'admin.oauth.servers' }
                    }
                ],
                sort: 14
            },
            {
                iconClassName: 'common-aside-seo',
                label: 'SEO',
                children: [
                    {
                        label: 'Main Pages',
                        to: { name: 'admin.seo.main-pages' }
                    },
                    {
                        label: 'Categories',
                        to: { name: 'admin.seo.categories' }
                    },
                    {
                        label: 'Posts',
                        to: { name: 'admin.seo.posts' }
                    },
                    {
                        label: 'Articles',
                        to: { name: 'admin.seo.articles' }
                    }
                ],
                sort: 15
            },
            {
                iconClassName: 'common-aside-notification-bell',
                label: 'Notifications',
                to: { name: 'admin.notifications.email-lists' },
                sort: 16
            },
            {
                iconClassName: 'common-aside-settings',
                label: 'Settings',
                to: { name: 'home' },
                sort: 17
            },
            {
                iconClassName: 'common-aside-log',
                label: 'Logs',
                to: { name: 'admin.logs' },
                sort: 18
            }
        ]
    }

    /**
     * Computes navigation items based on user role and enabled modules.
     *
     * @returns {ComputedRef<AsideInitialItem[]>} - The computed list of navigation items.
     */
    const NavItems: ComputedRef<AsideInitialItem[]> = computed(() => {
        const { HAS_BILLING, HAS_FM } = MODULES_BOOLEANS

        const navObj: Record<ROLE_NAME, AsideInitialItem[]> = Object.values(ROLE_NAME).reduce((acc, role) => {
            acc[role] = []
            return acc
        }, {} as Record<ROLE_NAME, AsideInitialItem[]>)

        navObj[ROLE_NAME.USER] = GET_USER_ASIDE_NAV_ITEMS()
        navObj[ROLE_NAME.ADMIN] = GET_ADMIN_ASIDE_NAV_ITEMS()

        HAS_BILLING && navObj[ROLE_NAME.USER].push({
            iconClassName: 'common-aside-billing',
            label: trans(appStore.translations.user_sidebar_menu, 'billing'),
            to: { name: billingStore.currentSub ? 'billing.membership' : 'billing.order2' },
            sort: 3
        })

        HAS_FM && navObj[ROLE_NAME.USER].push({
            iconClassName: 'common-aside-family-sharing',
            label: trans(appStore.translations.user_sidebar_menu, 'family_sharing'),
            to: { name: 'features.family-sharing' },
            sort: 5
        })

        function getItems(navObj: NavObj, ROLE: any): any {
            if (Object.prototype.hasOwnProperty.call(navObj, ROLE) && ROLE in ROLE_NAME) {
                const result: AsideInitialItem[] = navObj[ROLE as keyof NavObj]
                return result.sort((a: AsideInitialItem, b: AsideInitialItem) => a.sort - b.sort)
            }
            else {
                return []
            }
        }

        return getItems(navObj, ROLE.value)
    })

    /**
     * Computes the current route based on the route parameter.
     *
     * @returns {ComputedRef<AsideItem | AsideChildItem | null>} - The current route information.
     */
    const CurrentRoute: ComputedRef<AsideItem | AsideChildItem | null> = computed(() => {
        if (!asideHtmlEl.value) {
            return null
        }

        const foundItem = asideHtmlEl.value.Items
            .map((el: AsideItem) => (el.children.length ? el.children.flat() : el))
            .flat()
            .find((item: any) => item?.to?.name === route.name)

        return foundItem || null
    })

    /**
     * Computes user avatar attributes including the user's name, email, and avatar URL.
     *
     * @returns {ComputedRef<{ items: Array, name: string, email: string, avatar: string | null, hasSubscription: boolean }>} - The user avatar attributes.
     */
    const AvatarAttrs = computed(() => {
        if (!authStore.user) {
            return null
        }

        const userAvatarItems = [
            {
                label: 'Profile Settings',
                route: {
                    name: 'account.profile'
                },
                iconClassName: 'common-account-settings',
                isExternal: false
            },
            {
                label: 'Notification Settings',
                route: {
                    name: 'account.profile'
                },
                iconClassName: 'common-notification-settings',
                isExternal: false
            },
            {
                label: 'Download apps',
                route: {
                    name: 'account.profile'
                },
                iconClassName: 'common-download-apps',
                isExternal: false
            },
            {
                label: 'Help and support',
                route: {
                    name: 'account.profile'
                },
                iconClassName: 'common-aside-faq',
                isExternal: false
            }
        ]

        const adminAvatarItems = [
            {
                label: 'Profile Settings',
                route: {
                    name: 'admin.account.profile'
                },
                iconClassName: 'common-account-settings',
                isExternal: false
            }
        ]

        const commonItems = [{
            label: 'Sign Out',
            isAction: true,
            iconClassName: 'common-logout',
            isExternal: false,
            onClick: async () => {
                try {
                    appStore.isLogoutOverlayOpened = true
                    await authStore.LOGOUT({ is_overlay_enabled: true })
                    await router.push({ name: 'signin' })
                    window.epicWebSiteChannel.postMessage({ action: 'LOGOUT' })
                    await U_WAIT(1000)
                    appStore.isLogoutOverlayOpened = false
                }
                catch (error) {
                    U_CATCH_ERROR(error)
                }
            }
        }]

        const domain: string = window.location.origin

        return {
            items: isAdminOrAuthor.value ? [...adminAvatarItems, ...commonItems] : [...userAvatarItems, ...commonItems],
            name: authStore.user.name,
            email: authStore.user.email,
            avatar: authStore.user.photo ? `${domain}/${authStore.user.photo}` : null,
            hasSubscription: billingStore.currentSub
        }
    })

    /**
     * Computes a right navigation list based on user authentication and device screen resolution.
     *
     * @returns {ComputedRef<{ label: string, to: RouteLocationRaw }[]>} - The right navigation list.
     */
    const RightNavList: ComputedRef<{ label: string, to: RouteLocationRaw }[]> = computed(() => {
        if (props.headerType === ENUMS.HEADER_TYPES.USER_ADMIN) {
            return []
        }

        if (authStore.user) {
            if (IsDesktop.value) {
                return [{ label: 'Help center', to: { path: '/' } }]
            }
            return []
        }

        if (IsDesktop.value) {
            return [
                { label: 'Help center', to: { path: '/' } },
                { label: 'Log In', to: { name: 'signin' } }
            ]
        }
        else if (IsTablet.value) {
            return [{ label: 'Log In', to: { name: 'signin' } }]
        }

        return []
    })

    /**
     * Computes whether the aside menu is currently opened.
     *
     * @returns {ComputedRef<boolean>} - True if the aside menu is opened, otherwise false.
     */
    const IsAsideOpened: ComputedRef<boolean> = computed(() => asideHtmlEl.value.opened && asideHtmlEl.value.animationEnded)

    return {
        BURGER_ON_TOGGLE,
        ROUTE_CLICK,
        NavItems,
        RightNavList,
        CurrentRoute,
        AvatarAttrs,
        IsAsideOpened,
        burgerIsActive,
        footerNavList
    }
}
