<template>
    <div ref="select" v-clickoutside="onBlur" :class="['select', props.selectDisabled && 'disabled']" :style="selectStyle">
        <div class="select__inner" :style="selectInnerStyle" @click.stop="toggle">
            <div :class="['select__label']" :style="{ paddingRight: props.arrowIsEnabled && U_GET_STYLE_VAL(props.arrowOffset) }">
                <slot v-if="props.option && Object.keys(props.option).length" name="header" :header="props.option"></slot>
                <span v-else class="select__placeholder">{{ props.placeholder }}</span>
                <div v-if="props.arrowIsEnabled" :class="['select__arrow', state.visible && 'active']">
                    <svg class="select__arrow__icon" width="10" height="10" viewBox="0 0 320 208" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M143 200.3L7.00001 64.3C-2.39999 54.9 -2.39999 39.7 7.00001 30.4L29.6 7.8C39 -1.6 54.2 -1.6 63.5 7.8L159.9 104.2L256.3 7.8C265.7 -1.6 280.9 -1.6 290.2 7.8L312.8 30.4C322.2 39.8 322.2 55 312.8 64.3L176.8 200.3C167.6 209.7 152.4 209.7 143 200.3V200.3Z"
                            fill="black"
                        />
                    </svg>
                </div>
            </div>
        </div>

        <div v-if="state.visible" :class="{ dropdown: true, 'dropdown--loading': props.loading }" :style="dropdownStyle" @click.stop>
            <div v-if="!props.loading" class="dropdown__inner" :style="dropdownInnerStyle">
                <div
                    v-if="slots.dropdownBefore"
                    class="dropdown__before"
                    :style="{
                        display: 'flex',
                        padding: `10px  ${U_GET_STYLE_VAL(props.selectPadding)}`,
                        borderBottom: `${U_GET_STYLE_VAL(props.borderWidth)} solid ${props.borderColor}`,
                    }"
                >
                    <slot name="dropdownBefore"></slot>
                </div>

                <ul v-if="!props.groupedOptions" class="dropdown__ul">
                    <template v-if="props.options && props.options.length">
                        <li v-for="(item, i) in props.options" :key="i" :class="optionClass(item)" :style="optionStyle" @click.stop="!item.disabled && onSelect(item, i)">
                            <div class="dropdown__li__content">
                                <slot name="option" :item="item"></slot>
                            </div>
                        </li>
                    </template>
                    <li v-else class="dropdown__li disabled" :style="optionStyle">
                        <div class="dropdown__li__content">No options</div>
                    </li>
                </ul>

                <div v-if="props.groupedOptions">
                    <template v-if="props.options.length">
                        <template v-if="props.groupedOptionsAccordion">
                            <AppAccordion
                                v-for="(group, i) in props.options"
                                :key="i"
                                :ref="`accordion${i}`"
                                :class="[
                                    'dropdown__accordion',
                                    group[props.activeComparisonKey] && 'dropdown__accordion--clickable',
                                    group.disabled && 'dropdown__accordion--clickable-disabled-header',
                                    group[props.activeComparisonKey] === props.option[props.activeComparisonKey] && 'active',
                                ]"
                                :header-styles-props="{
                                    borderBottom: i < props.options.length - 1 ? `${U_GET_STYLE_VAL(props.borderWidth)} solid ${props.borderColor}` : 'none',
                                    minHeight: U_GET_STYLE_VAL(props.optionHeight),
                                    padding: props.dropdownMaxHeight
                                        ? `0 ${
                                              typeof props.selectPadding == 'string'
                                                  ? U_GET_STYLE_VAL(`calc(${props.selectPadding} + 2px)`)
                                                  : U_GET_STYLE_VAL(props.selectPadding + 2)
                                          }`
                                        : `0 ${U_GET_STYLE_VAL(props.selectPadding)}`,
                                }"
                                :body-styles-props="{ background: '#fff' }"
                                :body-inner-styles-props="{ padding: 0 }"
                                :opened="group.opened"
                                :index="i"
                                @on-toggle="$emit('accordion-on-toggle', $event)"
                            >
                                <template #header>
                                    <div v-if="slots.dropdownAccordionHeader" class="width-100-percent" @click="!group.disabled && onSelect(group, i)">
                                        <slot name="dropdownAccordionHeader" :group="group"></slot>
                                    </div>
                                    <div v-else class="font-weight-700">{{ group.label || group.name }}</div>
                                </template>
                                <div>
                                    <div
                                        v-if="slots.dropdownAccordionBefore"
                                        class="dropdown__before"
                                        :style="{
                                            display: 'flex',
                                            padding: `10px  ${U_GET_STYLE_VAL(props.selectPadding)}`,
                                            borderBottom: `${U_GET_STYLE_VAL(props.borderWidth)} solid ${props.borderColor}`,
                                        }"
                                    >
                                        <slot name="dropdownAccordionBefore" :group="group"></slot>
                                    </div>
                                    <ul class="dropdown__ul group">
                                        <li
                                            v-for="(item, k) in group.options"
                                            :key="k"
                                            :class="{
                                                active: props.option && item[props.activeComparisonKey] === props.option[props.activeComparisonKey],
                                                dropdown__li: true,
                                                disabled: item.disabled,
                                            }"
                                            :style="optionStyle"
                                            @click="!item.disabled && onSelect(item, i)"
                                        >
                                            <div class="dropdown__li__content">
                                                <slot name="option" :item="item"></slot>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </AppAccordion>
                        </template>
                        <template v-else>
                            <ul v-for="(group, key, i) in props.options" :key="i" class="dropdown__ul group">
                                <p class="group__title" :style="{ minHeight: U_GET_STYLE_VAL(props.optionHeight) }">{{ group.label }}</p>
                                <li
                                    v-for="(item, j) in group.options"
                                    :key="j"
                                    :class="{
                                        active: item[props.activeComparisonKey] === props.option[props.activeComparisonKey],
                                        dropdown__li: true,
                                        disabled: item.disabled,
                                    }"
                                    :style="optionStyle"
                                    @click="!item.disabled && onSelect(item, i)"
                                >
                                    <div class="dropdown__li__content">
                                        <slot name="option" :item="item"></slot>
                                    </div>
                                </li>
                            </ul>
                        </template>
                    </template>
                    <li v-else class="dropdown__li disabled" :style="optionStyle">
                        <div class="dropdown__li__content">No options</div>
                    </li>
                </div>
            </div>
            <AppLoader v-else :width="36" :height="36" :type="2" />
        </div>
        <!-- <p v-if="option.error && !state.visible" class="select-error">{{ props.defaultErrorMessage }}</p> -->
    </div>
</template>

<script setup>
import { computed, reactive, useSlots } from "vue";
import { U_GET_STYLE_VAL } from "platform-module";
import { AppLoader, AppAccordion } from "vue-components";

const slots = useSlots();
const emit = defineEmits(["accordion-on-toggle", "on-change", "dropdown-on-toggle", "on-select", "on-multi-select", "on-multi-delete"]);
const props = defineProps({
    options: {
        type: Object,
        default: () => null,
    },

    selectDisabled: {
        type: Boolean,
        default: false,
    },

    groupedOptions: {
        default: false,
        type: Boolean,
    },

    groupedOptionsAccordion: {
        default: false,
        type: Boolean,
    },

    placeholder: {
        default: "Select value",
        type: String,
    },

    option: {
        default: () => {
            return { value: "", name: "", icon: null };
        },
        type: [Object, Array],
    },

    dropdownAlign: {
        type: String,
        default: "start",
    },

    icon: {
        type: Object,
        default: null,
    },

    dropdownOffset: {
        type: [Number, String],
        default: 0,
    },

    dropdownPadding: {
        type: String,
        default: "",
    },

    dropdownStatic: {
        type: Boolean,
        default: false,
    },

    dropdownWidth: {
        type: [Number, String],
        default: null,
    },

    dropdownMaxHeight: {
        type: [Number, String],
        default: null,
    },

    dropdownBackground: {
        type: String,
        default: null,
    },

    optionHeight: {
        type: [Number, String],
        default: 30,
    },

    optionPadding: {
        type: String,
        default: "",
    },

    labelText: {
        type: Boolean,
        default: true,
    },

    arrowIsEnabled: {
        type: Boolean,
        default: true,
    },

    selectHeight: {
        type: [Number, String],
        default: null,
    },

    selectWidth: {
        type: [Number, String],
        default: null,
    },

    borderRadius: {
        type: Number,
        default: 8,
    },

    selectBorder: {
        type: Boolean,
        default: false,
    },

    selectBackground: {
        type: String,
        default: "#fff",
    },

    borderWidth: {
        type: Number,
        default: 1,
    },

    arrowOffset: {
        type: Number,
        default: 15,
    },

    borderColor: {
        type: String,
        default: "#dfe1e6",
    },

    selectPadding: {
        type: [Number, String],
        default: null,
    },

    hideOnChange: {
        type: Boolean,
        default: true,
    },

    loading: {
        default: false,
        type: Boolean,
    },

    activeComparisonKey: {
        type: String,
        default: "value",
    },

    defaultErrorMessage: {
        type: String,
        default: "This field is required.",
    },
    isMultiSelect: {
        type: Boolean,
        default: false,
    },
});

const state = reactive({
    visible: false,
    haveOpenedAccordion: false,
    header: {},
});

const dropdownStyle = computed(() => {
    let obj;
    const value = U_GET_STYLE_VAL(props.borderRadius);
    const common = {
        background: props.dropdownBackground,
        borderRadius: props.selectBorder && state.visible ? `0 0 ${value} ${value}` : U_GET_STYLE_VAL(props.borderRadius),
        minWidth: props.dropdownWidth || "100%",
        padding: props.dropdownPadding,
        border: `${U_GET_STYLE_VAL(props.borderWidth)} solid ${props.borderColor}`,
        borderTop: props.selectBorder && "none",
        position: !props.dropdownStatic && "absolute",
        transform: !props.dropdownStatic && "translate(0, 100%)",
        bottom: U_GET_STYLE_VAL(props.dropdownOffset),
        overflow: props.dropdownMaxHeight ? "unset" : "hidden",
    };

    if (props.dropdownAlign == "start" && !props.dropdownStatic) {
        obj = {
            left: 0,
            ...common,
        };
    } else if (props.dropdownAlign == "end" && !props.dropdownStatic) {
        obj = {
            right: 0,
            ...common,
        };
    } else {
        obj = {
            ...common,
        };
    }
    return obj;
});

const dropdownInnerStyle = computed(() => {
    const br = U_GET_STYLE_VAL(props.borderRadius);
    return {
        overflowY: "auto",
        borderRadius: `0 0 ${br} ${br}`,
        width: "100%",
        maxHeight: U_GET_STYLE_VAL(props.dropdownMaxHeight),
    };
});

const selectStyle = computed(() => {
    return {
        minHeight: U_GET_STYLE_VAL(props.selectHeight),
        minWidth: U_GET_STYLE_VAL(props.selectWidth),
    };
});

const selectInnerStyle = computed(() => {
    const value = U_GET_STYLE_VAL(props.borderRadius);
    return {
        padding: `0 ${U_GET_STYLE_VAL(props.selectPadding)}`,
        border: props.selectBorder && `${U_GET_STYLE_VAL(props.borderWidth)} solid ${props.borderColor}`,
        borderRadius: props.selectBorder && state.visible ? `${value} ${value} 0 0` : U_GET_STYLE_VAL(props.borderRadius),
        background: props.selectBackground,
    };
});

const optionStyle = computed(() => {
    return {
        minHeight: U_GET_STYLE_VAL(props.optionHeight),
        padding: U_GET_STYLE_VAL(props.optionPadding),
    };
});

const checkIfOptionAdded = (option) => props.option.map((el) => el[props.activeComparisonKey]).includes(option[props.activeComparisonKey]);

const optionClass = (option) => {
    return {
        active: props.option && (!props.isMultiSelect ? props.option[props.activeComparisonKey] == option[props.activeComparisonKey] : checkIfOptionAdded(option)),
        dropdown__li: true,
        disabled: props.option && option.disabled,
    };
};

const toggle = () => {
    state.visible = !state.visible;
    emit("dropdown-on-toggle", state.visible);
};

const onBlur = () => (state.visible = false);

const onSelect = (option, i) => {
    if (!props.isMultiSelect) {
        emit("on-select", option, i);
        if (props.hideOnChange && !props.isMultiSelect) state.visible = !state.visible;
    } else {
        checkIfOptionAdded(option) ? emit("on-multi-delete", option, i) : emit("on-multi-select", option, i);
    }
};

defineExpose({ onBlur });
</script>

<style lang="scss" scoped>
.select {
    position: relative;
    display: flex;
    flex-direction: column;

    &:focus-visible {
        outline: none;
    }

    &__loader {
        margin: 10px 0 10px 0;
    }

    &__inner {
        user-select: none;
        display: inline-flex;
        align-items: center;
        width: 100%;
        min-height: inherit;
        transition: $transitionPrimary;
    }

    &__label {
        cursor: pointer;
        user-select: none;
        display: flex;
        align-items: center;
        position: relative;
        width: 100%;
    }

    &__placeholder {
        color: $inputPlaceholder;
    }

    &.active {
        background: $colorDarkGrey;
        border-color: $colorDarkGrey !important;

        .select {
            &__value {
                color: $colorWhite;
            }
        }
    }

    &__arrow {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(0, -50%);
        width: 10px;
        height: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $colorDark;

        &__icon {
            transition: 0.3s all ease;
            width: 100%;
            height: 100%;
        }

        &.active {
            .select__arrow__icon {
                transform: scale(-1);
            }
        }
    }

    &.disabled {
        pointer-events: none;
        color: $colorLightGrey;

        .select {
            &__label {
                color: $inputPlaceholder;
            }

            &__arrow__icon {
                path {
                    fill: $inputPlaceholder;
                }
            }
        }
    }

    &-error {
        font-size: 12px;
        color: $colorError;
        margin: 4px 0 0 10px;
        line-height: 14px;
    }
}

.dropdown {
    background: $colorWhite;
    border-radius: $brPrimary;
    z-index: 99;
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.1);
    border: 2px solid $colorLightGrey;

    &--loading {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__before {
        width: 100%;
    }

    &__accordion {
        height: 100%;
    }

    &__inner {
        height: 100%;

        &::-webkit-scrollbar {
            width: 2px;
            height: 10px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            border-radius: 8px;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: darken($color: $colorLightGrey, $amount: 8);
            border-radius: 8px;
            transition: 0.3s all ease;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: darken($color: $colorLightGrey, $amount: 8);
        }
    }

    &__ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    &__li {
        margin: 0;
        display: flex;
        align-items: center;
        // min-width: 130px;
        width: 100%;
        position: relative;

        &__content {
            position: relative;
            z-index: 2;
            display: flex;
            align-items: center;
            width: 100%;
        }

        &:not(.disabled) {
            cursor: pointer;
        }

        &:hover {
            &:not(.disabled) {
                background: darken($color: $colorLightGreySecondary, $amount: 8);
            }
        }

        &.active {
            background: $colorLightGreySecondary;
        }

        &.disabled {
            color: $colorLightGrey;
        }
    }

    .group {
        overflow: hidden;

        &:not(:last-child) {
            margin-bottom: 6px;
        }

        &__title {
            color: $colorGrey;
            font-weight: 600;
            margin: 0;
            padding: 0 16px;
            display: flex;
            align-items: center;
        }
    }
}

:deep(.dropdown__accordion--clickable) {
    &:not(.dropdown__accordion--clickable-disabled-header) {
        .accordion__header {
            &:hover {
                background: darken($color: $colorLightGreySecondary, $amount: 8) !important;
            }
        }
    }
}

$activeAccordionBorderColor: #42b883;

:deep(.dropdown__accordion--clickable) {
    .accordion {
        &__header {
            border-left: 1px solid transparent !important;
            border-right: 1px solid transparent !important;
            border-top: 1px solid transparent !important;

            &.active {
                border-left: 1px solid $activeAccordionBorderColor !important;
                border-right: 1px solid $activeAccordionBorderColor !important;
                border-top: 1px solid $activeAccordionBorderColor !important;
            }
        }

        &-body {
            border-left: 1px solid transparent !important;
            border-right: 1px solid transparent !important;
            border-bottom: 1px solid transparent !important;

            &.active {
                border-left: 1px solid $activeAccordionBorderColor !important;
                border-right: 1px solid $activeAccordionBorderColor !important;
                border-bottom: 1px solid $activeAccordionBorderColor !important;
            }
        }
    }
}

:deep(.dropdown__accordion--clickable-disabled-header) {
    .accordion {
        &__header {
            color: $colorLightGrey;
        }
    }
}

:deep(.dropdown__accordion.active .accordion__header) {
    background: darken($color: $colorLightGreySecondary, $amount: 8) !important;
}

.fade-up-enter-active,
.fade-up-leave-active {
    transition: all 0.2s;
}
.fade-up-enter,
.fade-up-leave-to {
    opacity: 0;
    transform: translateY(103%);
}
</style>
