<template>
    <svg width="24" height="16" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M22 0H2C0.89543 0 0 0.89543 0 2V14C0 15.1046 0.89543 16 2 16H22C23.1046 16 24 15.1046 24 14V2C24 0.89543 23.1046 0 22 0Z" fill="#016FD0" />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M13.7642 13.3938V7.69238L23.9117 7.70149V9.27638L22.7388 10.5298L23.9117 11.7947V13.403H22.0391L21.0439 12.3048L20.0558 13.4071L13.7642 13.3938Z"
            fill="#FFFFFE"
        />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M14.4419 12.7687V8.31982H18.2142V9.34471H15.6633V10.0404H18.1534V11.0482H15.6633V11.7315H18.2142V12.7687H14.4419Z" fill="#016FD0" />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M18.1952 12.7686L20.2825 10.5416L18.1951 8.31982H19.8107L21.0862 9.72986L22.3653 8.31982H23.9115V8.35483L21.8686 10.5416L23.9115 12.7055V12.7686H22.3498L21.0516 11.3444L19.7669 12.7686H18.1952Z"
            fill="#016FD0"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M14.2374 2.63184H16.6834L17.5426 4.58269V2.63184H20.5624L21.0832 4.09341L21.6057 2.63184H23.9116V8.33323H11.7251L14.2374 2.63184Z"
            fill="#FFFFFE"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M14.7006 3.25098L12.7266 7.69615H14.0805L14.4529 6.80598H16.4708L16.843 7.69615H18.2306L16.2648 3.25098H14.7006ZM14.8702 5.80841L15.4622 4.39334L16.0538 5.80841H14.8702Z"
            fill="#016FD0"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M18.2122 7.69557V3.25049L20.1153 3.25703L21.0945 5.98976L22.0802 3.25049H23.9118V7.69557L22.7332 7.706V4.65265L21.6206 7.69557H20.5448L19.4092 4.64222V7.69557H18.2122Z"
            fill="#016FD0"
        />
    </svg>
</template>
