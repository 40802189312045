<template>
  <svg width="448" height="512" viewBox="0 0 448 512" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_70:14)">
      <path
        d="M439.39 362.29C420.07 341.53 383.92 310.3 383.92 208C383.92 130.3 329.44 68.1 255.98 52.84V32C255.98 14.33 241.66 0 224 0C206.34 0 192.02 14.33 192.02 32V52.84C118.56 68.1 64.08 130.3 64.08 208C64.08 310.3 27.93 341.53 8.61 362.29C2.61 368.74 -0.0500029 376.45 -2.88669e-06 384C0.109997 400.4 12.98 416 32.1 416H415.9C435.02 416 447.9 400.4 448 384C448.05 376.45 445.39 368.73 439.39 362.29V362.29ZM67.53 368C88.75 340.03 111.95 293.67 112.06 208.58C112.06 208.38 112 208.2 112 208C112 146.14 162.14 96 224 96C285.86 96 336 146.14 336 208C336 208.2 335.94 208.38 335.94 208.58C336.05 293.68 359.25 340.04 380.47 368H67.53ZM224 512C259.32 512 287.97 483.35 287.97 448H160.03C160.03 483.35 188.68 512 224 512Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_70:14">
        <rect width="448" height="512" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>