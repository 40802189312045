export function SET_DIRECTIVES(app) {
    app.directive('clickoutside', {
    // TODO: remove
        mounted(el, binding) {
            el.clickOutsideEvent = function (event) {
                if (!(el === event.target || el.contains(event.target))) {
                    binding.value(event, el);
                }
            };
            document.body.addEventListener('mousedown', el.clickOutsideEvent);
        },
        unmounted(el) {
            document.body.removeEventListener('mousedown', el.clickOutsideEvent);
        }
    });
}
