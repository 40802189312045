<template>
    <NewCountrySelect
        v-model="option"
        :sprite="sprite" label-by="label" value-by="code"
        :options="LangOptions"
        size="s" @update:model-value="CHANGE_LANG($event)"
    />
</template>

<script setup lang="ts">
import { NewCountrySelect } from 'vue-components2'
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { useAppStore } from '@/stores'
import sprite from '~/images/sprites/countries.svg'

const appStore = useAppStore()
const { LangOptions, SET_LANGUAGE } = appStore
const router = useRouter()
const initialLangOption = LangOptions.find((el: LangOption) => el.lang === appStore.locale)
const option = ref(initialLangOption)

interface LangOption {
    code: string
    lang: string
    label: string
}

/**
 * Changes the application's language by updating the router path to include the specified language.
 *
 * This function first calls `SET_LANGUAGE` to set the new language in the application.
 * It then retrieves the current router path and constructs a regular expression to detect
 * and replace the existing language prefix in the URL. If the new language path differs
 * from the current path, it navigates to the updated path.
 *
 * @async
 * @function CHANGE_LANG
 * @param options - An object containing the language option.
 * @param options.lang - The language code to switch to.
 * @returns - Resolves when the language change and route update are complete.
 * @throws Will log an error message to the console if language change fails.
 *
 * @example
 * // Change language to French
 * await CHANGE_LANG({ lang: 'en' });
 */
async function CHANGE_LANG({ lang }: LangOption): Promise<void> {
    try {
        // Set the new language
        await SET_LANGUAGE({ lang })

        // Get the current path
        const currentPath = router.currentRoute.value.path

        // Create a language detection regex pattern
        const languagePattern = `^/(${LangOptions.map((el: LangOption) => el.lang).join('|')})?/?`
        const languageDetector = new RegExp(languagePattern)

        // Generate the new path by replacing the detected language
        const newPath = currentPath.replace(languageDetector, `/${lang}/`)

        // Only update the route if the path has changed
        if (currentPath !== newPath) {
            await router.push({ path: newPath })
        }
    }
    catch (error) {
        // Improved error handling with logging
        console.error('Language change failed:', error)
    }
}
</script>
