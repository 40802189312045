<template>
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M37.9721 13.8199C38.0791 8.25491 33.4871 3.98291 27.1731 3.98291H14.1151C13.8108 3.98301 13.5164 4.09174 13.285 4.28955C13.0537 4.48735 12.9005 4.76124 12.8531 5.06191L7.62014 37.7579C7.59673 37.9061 7.60571 38.0575 7.64645 38.2019C7.6872 38.3462 7.75874 38.48 7.85615 38.5941C7.95357 38.7081 8.07454 38.7997 8.21075 38.8625C8.34695 38.9253 8.49515 38.9579 8.64514 38.9579H16.3821L15.1721 46.5299C15.1487 46.6781 15.1577 46.8297 15.1985 46.9741C15.2393 47.1185 15.311 47.2524 15.4085 47.3665C15.506 47.4805 15.6271 47.5721 15.7634 47.6348C15.8998 47.6976 16.0481 47.73 16.1981 47.7299H22.5001C22.8051 47.7299 23.0761 47.6199 23.3071 47.4229C23.5381 47.2249 23.5761 46.9519 23.6231 46.6509L25.4731 35.7659C25.5201 35.4659 25.6731 35.0759 25.9051 34.8779C26.1361 34.6799 26.3381 34.5719 26.6421 34.5709H30.5001C36.6831 34.5709 41.9301 30.1769 42.8891 24.0639C43.5671 19.7239 41.7071 15.7769 37.9731 13.8199H37.9721Z"
            fill="#001C64"
        />
        <path
            d="M18.056 26.9001L16.129 39.1201L14.919 46.7841C14.8955 46.9323 14.9045 47.0839 14.9453 47.2283C14.9862 47.3727 15.0578 47.5066 15.1553 47.6206C15.2528 47.7347 15.3739 47.8262 15.5103 47.889C15.6466 47.9517 15.7949 47.9842 15.945 47.9841H22.615C22.9192 47.9837 23.2133 47.8749 23.4444 47.6771C23.6756 47.4793 23.8286 47.2056 23.876 46.9051L25.634 35.7651C25.6814 35.4647 25.8344 35.191 26.0656 34.9934C26.2967 34.7958 26.5908 34.6872 26.895 34.6871H30.822C37.005 34.6871 42.251 30.1771 43.21 24.0641C43.89 19.7251 41.706 15.7781 37.972 13.8201C37.962 14.2821 37.922 14.7431 37.851 15.2001C36.892 21.3121 31.645 25.8231 25.462 25.8231H19.317C19.013 25.8231 18.719 25.9316 18.4879 26.129C18.2567 26.3264 18.1036 26.5998 18.056 26.9001V26.9001Z"
            fill="#0070E0"
        />
        <path
            d="M16.128 39.1199H8.36797C8.21793 39.12 8.06964 39.0876 7.93336 39.0249C7.79707 38.9621 7.67602 38.8705 7.57857 38.7564C7.48112 38.6423 7.40959 38.5085 7.36891 38.364C7.32824 38.2196 7.31938 38.0681 7.34297 37.9199L12.575 4.73791C12.6224 4.43733 12.7756 4.16357 13.007 3.96593C13.2383 3.76829 13.5327 3.65977 13.837 3.65991H27.174C33.487 3.65991 38.079 8.25491 37.972 13.8199C36.401 12.9959 34.555 12.5249 32.532 12.5249H21.413C21.1089 12.5252 20.8149 12.6339 20.5838 12.8315C20.3527 13.0291 20.1996 13.3026 20.152 13.6029L18.057 26.8999L16.127 39.1199H16.128Z"
            fill="#003087"
        />
    </svg>
</template>
